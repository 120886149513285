import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { WooCommerce } from "../../../src/lib/api";
import { wooProduct } from "../products/productInterface";
import { Breadcrumbs } from "../../../src/components/breadcrumbs";
import MyLoader from "../../../src/components/MyLoader";
import { Slider } from "../../components/ui/slider";
import Products from "../products/Products";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../../src/components/ui/toggle-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
  SelectTrigger,
} from "../../../src/components/ui/select";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../../components/ui/drawer";
import { FilterIcon } from "lucide-react";
import { Button } from "../../../src/components/ui/button";
import { wooCategory } from "../../../src/lib/interfaces";
import { Checkbox } from "../../../src/components/ui/checkbox";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../src/components/ui/radio-group";
import { Label } from "../../../src/components/ui/label";

const PRODUCTS_PER_PAGE = 50;
const getWooProducts = async (
  page: number
): Promise<wooProduct[]> => {
  try {
    const response = await WooCommerce.get(
      `products/?per_page=${PRODUCTS_PER_PAGE}&page=${page}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
export default function ShopPage() {
  const [sortedProducts, setSortedProducts] = useState<
    wooProduct[]
  >([]);
  const [filterText, setFilterText] = useState("");
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(100);
  const [sliderMin, setSliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(0);
  const [sortType, setSortType] = useState("");
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>([]);
  const [selectedBrand, setSelectedBrand] =
    useState<string>("");
  const [selectedTags, setSelectedTags] = useState<
    string[]
  >([]);
  const { categoryId, categoryName, tagId, tagName } =
    useParams<{
      categoryId?: string;
      categoryName?: string;
      tagId?: string;
      tagName?: string;
    }>();
  React.useEffect(() => {
    window.scrollTo(0, 25);
  }, []);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: ["woo-products"],
    queryFn: ({ pageParam = 1 }) =>
      getWooProducts(pageParam),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === PRODUCTS_PER_PAGE
        ? allPages.length + 1
        : undefined,
  });

  const allProducts = useMemo(
    () => data?.pages.flat() || [],
    [data]
  );
  const { data: categories } = useQuery(
    ["wooCategories"],
    async () => {
      const res: wooCategory[] = WooCommerce.get(
        "products/categories/?per_page=100"
      )
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );
  const { data: tags } = useQuery(["tags"], async () => {
    const res = WooCommerce.get(
      "products/tags/?per_page=100"
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    return res;
  });
  const filterProducts = useCallback(
    (products: wooProduct[]) => {
      if (categoryId) {
        return products.filter((product) =>
          product.categories.some(
            (category) => category.id === Number(categoryId)
          )
        );
      }
      if (tagId) {
        return products.filter((product) =>
          product.tags.some(
            (tag) => tag.id === Number(tagId)
          )
        );
      }

      return products;
    },
    [categoryId, tagId]
  );

  useEffect(() => {
    const filteredProducts = filterProducts(allProducts);
    setSortedProducts(filteredProducts);
    setSliderPrices(filteredProducts);
    if (hasNextPage && filteredProducts.length === 0) {
      fetchNextPage();
    }
  }, [
    allProducts,
    filterProducts,
    hasNextPage,
    fetchNextPage,
  ]);
  function setSliderPrices(products: wooProduct[]): void {
    const prices = products.map((product) => {
      const price = parseFloat(product.price);
      return isNaN(price) ? 0 : price;
    });

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    setPriceMin(minPrice);
    setPriceMax(maxPrice);
    setSliderMin(minPrice);
    setSliderMax(maxPrice);
  }

  const handleSortChange = (e: string) => {
    setSortType(e);
    let sortedArray = [...sortedProducts];
    if (e === "asc") {
      sortedArray.sort(
        (a, b) => Number(a.price) - Number(b.price)
      );
    } else if (e === "desc") {
      sortedArray.sort(
        (a, b) => Number(b.price) - Number(a.price)
      );
    } else if (e === "abc") {
      sortedArray.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } else if (e === "cba") {
      sortedArray.sort((a, b) =>
        b.name.localeCompare(a.name)
      );
    } else if (e === "newest" || "default") {
      sortedArray.sort(
        (a, b) =>
          new Date(b.date_created).getTime() -
          new Date(a.date_created).getTime()
      );
    } else if (e === "oldest") {
      sortedArray.sort(
        (a, b) =>
          new Date(a.date_created).getTime() -
          new Date(b.date_created).getTime()
      );
    }

    setSortedProducts(sortedArray);
  };
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footer");
      if (!footer) return;

      const footerTop = footer.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      const isReachFooter =
        footerTop <= viewportHeight &&
        footerTop <= viewportHeight + 100;

      if (isReachFooter) {
        fetchNextPage();
      }
    };

    const throttledHandleScroll = throttle(
      handleScroll,
      300
    );
    window.addEventListener(
      "scroll",
      throttledHandleScroll
    );
    return () =>
      window.removeEventListener(
        "scroll",
        throttledHandleScroll
      );
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  function filterByPrice(
    priceMin: number,
    priceMax: number
  ) {
    const sorted = filterProducts(allProducts);
    const filteredByPrice = sorted.filter(
      (product: wooProduct) => {
        const price = parseFloat(product.price);
        return (
          !isNaN(price) &&
          price >= priceMin &&
          price <= priceMax
        );
      }
    );

    setSortedProducts(filteredByPrice);

    setFilterText(
      `Prices between $${priceMin} and $${priceMax}`
    );
  }
  function filterByCategory() {
    if (selectedCategories.length === 0) {
      setSortedProducts(filterProducts(allProducts));
      return;
    }
    const filter = filterProducts(allProducts).filter(
      (product) =>
        product.categories.some((category) =>
          selectedCategories.includes(String(category.id))
        )
    );

    setSortedProducts(filter);
  }
  function filterByBrand() {
    if (selectedBrand === "") {
      filterProducts(allProducts);
      return;
    }
    const filter = filterProducts(allProducts).filter(
      (product) =>
        product.categories.some(
          (category) =>
            selectedBrand === String(category.id)
        )
    );

    setSortedProducts(filter);
  }
  function filterByTag() {
    if (selectedTags.length < 1) {
      filterProducts(allProducts);
      return;
    }
    const filter = filterProducts(allProducts).filter(
      (product) =>
        product.tags.some((tag) =>
          selectedTags.includes(String(tag.id))
        )
    );

    setSortedProducts(filter);
  }
  // Function to handle checkbox changes
  const handleCheckedCategoriesChange = (
    checked: boolean,
    categoryId: string
  ) => {
    if (checked) {
      // Add category ID to the state if checked
      setSelectedCategories((prev) => [
        ...prev,
        categoryId,
      ]);
    } else {
      // Remove category ID from the state if unchecked
      setSelectedCategories((prev) =>
        prev.filter((id) => id !== categoryId)
      );
    }
  };
  const handleCheckedTagsChange = (
    checked: boolean,
    tagId: string
  ) => {
    if (checked) {
      // Add category ID to the state if checked
      setSelectedTags((prev) => [...prev, tagId]);
    } else {
      // Remove category ID from the state if unchecked
      setSelectedTags((prev) =>
        prev.filter((id) => id !== tagId)
      );
    }
  };

  React.useEffect(() => {
    filterByCategory();
  }, [selectedCategories]);
  React.useEffect(() => {
    filterByBrand();
  }, [selectedBrand]);
  React.useEffect(() => {
    filterByTag();
  }, [selectedTags]);
  const clearFilter = () => {
    const filteredProducts = filterProducts(allProducts);
    setSortedProducts(filteredProducts);
    setFilterText("");
    handleSortChange("default");
    setSliderPrices(allProducts);
    setSelectedTags([]);
    setSelectedCategories([]);
    setSelectedBrand("");
  };
  if (error)
    return (
      <div>
        Error loading products. Please try again later.
      </div>
    );

  return isLoading ? (
    <div className="h-screen w-screen flex justify-center items-center">
      <MyLoader />
    </div>
  ) : (
    <div className="lg:p-32 p-5 flex flex-col font-montserrat w-full gap-10 mt-16">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl font-montserrat font-medium">
          {categoryName || tagName || "SHOP"}
        </h1>
        {!categoryName && !tagName ? (
          <Breadcrumbs type={"shop"} />
        ) : (
          <Breadcrumbs
            categoryName={categoryName}
            categoryId={categoryId}
            tagName={tagName}
            tagId={tagId}
          />
        )}
      </div>
      <div className="flex justify-between w-full">
        <div className="flex flex-col gap-2 justify-end items-start">
          <div className="flex gap-2">
            <Drawer>
              <DrawerTrigger className="flex gap-1 justify-center items-center">
                <span className=" text-md font-montserrat  hover:underline">
                  Filter
                </span>
                <FilterIcon className="w-4 h-4" />
              </DrawerTrigger>
              <DrawerContent className="p-5 font-montserrat">
                <DrawerHeader className="p-0 mb-5 mt-5">
                  <DrawerTitle>Filter </DrawerTitle>
                  <DrawerDescription>
                    Filter your products
                  </DrawerDescription>
                </DrawerHeader>
                <div className="flex gap-5 items-stretch sm:flex-row flex-col max-h-[550px] overflow-scroll no-scrollbar ">
                  <div className="flex gap-5 w-full flex-wrap">
                    <div className="flex flex-col border p-2 w-full sm:hidden block">
                      <p className="mb-4 font-montserrat font-medium">
                        Sort By
                      </p>
                      <RadioGroup className="flex flex-col gap-5">
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="default"
                            id="default"
                          />
                          <Label htmlFor="default">
                            Default
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="asc"
                            id="asc"
                          />
                          <Label htmlFor="asc">
                            Price Ascending
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="desc"
                            id="desc"
                          />
                          <Label htmlFor="desc">
                            Price Descending
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="newest"
                            id="newest"
                          />
                          <Label htmlFor="newest">
                            Newest
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="oldest"
                            id="oldest"
                          />
                          <Label htmlFor="oldest">
                            Oldest
                          </Label>
                        </div>

                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="abc"
                            id="abc"
                          />

                          <Label htmlFor="abc">
                            Title Ascending
                          </Label>
                        </div>

                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="cba"
                            id="cba"
                          />

                          <Label htmlFor="cba">
                            Title Descending
                          </Label>
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="flex flex-col border p-2 w-full">
                      <p className="mb-4 font-montserrat font-medium">
                        Price Range
                      </p>
                      <div className=" flex gap-1 items-center">
                        <p>{sliderMin}$</p>
                        <Slider
                          value={[sliderMin, sliderMax]}
                          onValueChange={(e) => {
                            setSliderMin(e[0]);
                            setSliderMax(e[1]);
                            filterByPrice(e[0], e[1]);
                          }}
                          // minStepsBetweenThumbs={5}
                          min={priceMin}
                          max={priceMax}
                        />
                        <p>{sliderMax}$</p>
                      </div>
                    </div>
                    {categoryName !== "Kerastase" &&
                      categoryName !== "Schwarzkopf" && (
                        <div className="flex flex-col border p-2 w-full">
                          <h4 className="mb-4 font-montserrat font-medium">
                            Brands
                          </h4>
                          <ToggleGroup
                            type="single"
                            className="flex gap-2 justify-start w-full"
                            onValueChange={(e) => {
                              setSelectedBrand(e);
                              console.log(e);
                            }}
                            value={selectedBrand}
                          >
                            <ToggleGroupItem
                              value="368"
                              variant="outline"
                              className="hover:bg-transparent sm:hover:bg-neutral-100"
                            >
                              Kerastase
                            </ToggleGroupItem>
                            <ToggleGroupItem
                              value="437"
                              variant="outline"
                              className="hover:bg-transparent sm:hover:bg-neutral-100"
                            >
                              Schwarzkopf
                            </ToggleGroupItem>
                          </ToggleGroup>
                        </div>
                      )}
                    <div className="flex flex-col gap-2 w-full">
                      <div className="flex flex-col border p-2">
                        <h4 className="mb-4 font-montserrat font-medium">
                          {categoryName
                            ? categoryName
                            : "Hair Care"}
                        </h4>
                        {categoryId === "368" ||
                        categoryId === "437" ||
                        categoryId === "378" ? (
                          <div className="flex gap-y-3 overflow-scroll no-scrollbar flex-wrap lg:h-full h-48">
                            {categories
                              ?.filter(
                                (category) =>
                                  category.parent ===
                                  Number(categoryId)
                              )
                              .map((cat) => (
                                <div
                                  className="flex items-end gap-1 sm:w-1/4 w-full"
                                  key={cat.id}
                                >
                                  <Checkbox
                                    id={`category-${cat.id}`}
                                    checked={selectedCategories.includes(
                                      String(cat.id)
                                    )}
                                    onCheckedChange={(
                                      checked
                                    ) => {
                                      handleCheckedCategoriesChange(
                                        Boolean(checked),
                                        String(cat.id)
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`category-${cat.id}`}
                                    className="text-sm w-fit leading-none"
                                  >
                                    {cat.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        ) : !categoryName ? (
                          <div className="flex gap-y-3 overflow-scroll no-scrollbar flex-wrap lg:h-full h-48">
                            {categories
                              ?.filter(
                                (category) =>
                                  category.parent === 17
                              )
                              .map((cat) => (
                                <div
                                  className="flex items-end gap-1 sm:w-1/6 w-full"
                                  key={cat.id}
                                >
                                  <Checkbox
                                    id={`category-${cat.id}`}
                                    checked={selectedCategories.includes(
                                      String(cat.id)
                                    )}
                                    onCheckedChange={(
                                      checked
                                    ) => {
                                      handleCheckedCategoriesChange(
                                        Boolean(checked),
                                        String(cat.id)
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`category-${cat.id}`}
                                    className="text-sm w-fit leading-none"
                                  >
                                    {cat.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="flex gap-y-3 overflow-scroll no-scrollbar flex-wrap lg:h-full h-48">
                            {tags?.filter((tag: any) =>
                              tag.slug.includes(
                                categoryName
                                  .toLocaleLowerCase()
                                  .slice(0, 4)
                              )
                            ).length > 0 ? (
                              tags
                                ?.filter((tag: any) =>
                                  tag.slug.includes(
                                    categoryName
                                      .toLocaleLowerCase()
                                      .slice(0, 4)
                                  )
                                )
                                .map((sub: any) => (
                                  <div
                                    className="flex items-end gap-1 md:w-1/4 w-full"
                                    key={sub.id}
                                  >
                                    <Checkbox
                                      id={`category-${sub.id}`}
                                      checked={selectedTags.includes(
                                        String(sub.id)
                                      )}
                                      onCheckedChange={(
                                        checked
                                      ) => {
                                        handleCheckedTagsChange(
                                          Boolean(checked),
                                          String(sub.id)
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`category-${sub.id}`}
                                      className="text-sm w-fit leading-none"
                                    >
                                      {sub.name}
                                    </label>
                                  </div>
                                ))
                            ) : (
                              <div className="h-full flex justify-center items-center w-full">
                                {" "}
                                not ready yet
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </DrawerContent>
            </Drawer>
            {filterText.length > 0 && (
              <Button
                variant={"ghost"}
                onClick={clearFilter}
              >
                Clear
              </Button>
            )}
          </div>
          <p className="text-sm text-neutral-600">
            {filterText}
          </p>
        </div>
        <div className="hidden sm:block">
          <Select
            onValueChange={(e) => handleSortChange(e)}
            value={sortType}
          >
            <SelectTrigger className="w-48 text-sm rounded-none outline-none focus:outline-none focus:ring-0">
              <SelectValue
                placeholder={"Sort By"}
                className="outline-none focus:outline-none"
              />
            </SelectTrigger>
            <SelectContent className="w-full cursor-pointer font-montserrat text-sm ">
              <SelectItem
                value="default"
                className="cursor-pointer"
              >
                Default
              </SelectItem>
              <SelectItem
                value="asc"
                className="cursor-pointer"
              >
                Price Ascending
              </SelectItem>
              <SelectItem
                value="desc"
                className="cursor-pointer"
              >
                Price Descending
              </SelectItem>
              <SelectItem
                value="newest"
                className="cursor-pointer"
              >
                Newest
              </SelectItem>
              <SelectItem
                value="oldest"
                className="cursor-pointer"
              >
                Oldest
              </SelectItem>
              <SelectItem
                value="abc"
                className="cursor-pointer"
              >
                Title Ascending
              </SelectItem>
              <SelectItem
                value="cba"
                className="cursor-pointer"
              >
                Title Descending
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="w-full ">
        <Products products={sortedProducts} />
      </div>
      {isFetchingNextPage && (
        <div className="h-32 w-full flex justify-center items-center">
          <img
            src="/logo-aaa.png"
            className="animate-ping w-8 dark:invert"
            alt="logo"
          />
        </div>
      )}
    </div>
  );
}

// Utility function for throttling
function throttle(func: Function, limit: number) {
  let inThrottle: boolean;
  return function (this: any) {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}
