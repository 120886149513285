import useWishlist from "../../../src/hooks/useWishlist";
import SearchSheet from "../../../src/components/searchSheet";
import Wishlist from "../../../src/components/Wishlist";
import { User } from "lucide-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartDrawer } from "../../../src/components/cartDrawer";
export default function MobileBar({
  isMobile,
}: {
  isMobile: boolean;
}) {
  const { data: wishlist } = useWishlist();
  const [showMenu, setShowMenu] = React.useState(false);
  const [lastScrollY, setLastScrollY] = React.useState(0);
  const nav = useNavigate();
  React.useEffect(() => {
    const handleShowMenu = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setShowMenu(true);
      } else if (currentScrollY <= lastScrollY) {
        // Scrolling up or at the top
        setShowMenu(false);
      }
      setLastScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleShowMenu);

    return () => {
      window.removeEventListener("scroll", handleShowMenu);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`duration-500 fixed bottom-0 z-50 dark:bg-neutral-900 bg-white border-t w-full pt-3 pb-3 flex justify-between ${
        showMenu ? "translate-y-20" : ""
      }`}
    >
      <div className=" w-full flex flex-col gap-2 justify-center items-center">
        <CartDrawer scrolled={isMobile} />
        <span className="text-[10px]">CART</span>
      </div>
      <div className="w-full flex flex-col gap-2 justify-center items-center">
        <SearchSheet scrolled={isMobile} />
        <span className="text-[10px]">SEARCH</span>
      </div>
      <button
        className="w-full flex flex-col gap-2 justify-center items-center"
        onClick={() => {
          nav("/");
          window.scrollTo(0, 0);
        }}
      >
        <img
          src="/logo-aaa.png"
          alt="amadeus"
          className="w-6 dark:invert"
        />
        <span className="text-[10px]">HOME</span>
      </button>
      <div className=" w-full flex flex-col gap-2 justify-center items-center">
        <Wishlist
          navBar
          wishlist={wishlist}
          scrolled={isMobile}
        />
        <span className="text-[10px]">WISHLIST</span>
      </div>
      <div className=" w-full flex flex-col gap-2 justify-center items-center">
        <Link
          to="/profile"
          // className={
          //   switchLogo || location !== "/"
          //     ? "sm:block hidden  text-neutral-900  hover:fill-neutral-900  dark:text-neutral-200 hover:fill-neutral-100"
          //     : "sm:block hidden  text-neutral-100 hover:fill-neutral-200 dark:text-neutral-200 hover:fill-neutral-100"
          // }
        >
          <User
            className="text-neutral-100 h-6 w-6 text-neutral-900 dark:text-neutral-100"
            //   className={
            //     switchLogo || location !== "/"
            //       ? "h-5 w-5 hover:fill-neutral-900 dark:hover:fill-neutral-200"
            //       : "h-5 w-5 hover:fill-neutral-200 dark:hover:fill-neutral-200"
            //   }
          />
        </Link>
        <span className="text-[10px]">ACCOUNT</span>
      </div>
    </div>
  );
}
