import { Link, useNavigate } from "react-router-dom";
import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../src/components/ui/carousel";

interface Image {
  id: number;
  source: string;
  tagId: number;
}
interface Tag {
  id: number;
  name: string;
}
export default function HairConcern() {
  const nav = useNavigate();
  const [hoveredIndex, setHoveredIndex] = React.useState<
    number | null
  >(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const images: Image[] = [
    { id: 1, source: "/damaged.webp", tagId: 417 },
    { id: 2, source: "/dry.webp", tagId: 386 },
    { id: 3, source: "/colored.webp", tagId: 442 },
    { id: 4, source: "/anti.jpeg", tagId: 407 },
    { id: 4, source: "/curly.webp", tagId: 444 },
    { id: 4, source: "/dull.webp", tagId: 455 },
  ];
  const tags: Tag[] = [
    { id: 417, name: "Damaged Hair" },
    { id: 386, name: "Dry Hair" },
    { id: 442, name: "Colored Hair" },
    { id: 407, name: "Anti Dandruff" },
    { id: 444, name: "Curly Hair" },
    { id: 455, name: "Dull Hair" },
  ];
  const mapTagsToImages = (
    tags: Tag[],
    images: Image[]
  ) => {
    return tags.map((tag) => ({
      ...tag,
      images: images.filter(
        (image) => image.tagId === tag.id
      ),
    }));
  };

  const tagsWithImages = mapTagsToImages(tags, images);
  // Display the tags with their associated images

  return (
    <div className="flex flex-col font-montserrat gap-5 pl-0">
      <div className="flex flex-col gap-2 mb-10">
        <h3 className="sm:text-3xl font-medium text-xl font-montserrat text-center">
          HAIR CONCERN
        </h3>
        <p className="text-center text-sm ">
          Discover the iconic products that make our brand
          so unique in the professional haircare industry
        </p>
        <Link
          to="/shop"
          className="text-center underline text-sm font-medium"
        >
          VIEW ALL
        </Link>
      </div>
      <Carousel
        opts={{ loop: false, skipSnaps: true }}
        className="relative"
      >
        <CarouselContent className="flex gap-5 ml-0">
          {tagsWithImages?.map((tag, index) => (
            <CarouselItem
              key={tag.id}
              className="basis-auto p-0 relative cursor-pointer"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave()}
              onClick={() =>
                nav(`/shop/hairType/${tag.id}/${tag.name}`)
              }
            >
              {" "}
              {hoveredIndex === index && (
                <div
                  key={index}
                  className={`absolute w-full bg-neutral-800 z-30 h-full opacity-20`}
                ></div>
              )}
              <div
                key={tag.id}
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`}
                data-aos-duration={`${index * 40}`}
                className="border border-neutral-200 sm:w-72 w-48  relative cursor-pointer"
              >
                <span className="absolute bottom-0 p-5 w-full font-montserrat z-10 text-center font-[400] sm:text-xl text-md  dark:text-neutral-900 ">
                  {tag.name.toLocaleUpperCase()}
                </span>

                <div className="absolute h-1/3 bottom-0 from-neutral-200 w-full bg-gradient-to-t"></div>

                {tag.images.map((image) => (
                  <img
                    key={image.id}
                    src={image.source}
                    alt={tag.name}
                    className="w-full"
                  />
                ))}
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="z-30 left-0 absolute h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        <CarouselNext className="absolute z-30 right-0  h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
      </Carousel>
    </div>
  );
}
