import * as React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import { useQuery } from "react-query";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../src/components/ui/card";
import { StarFilledIcon } from "@radix-ui/react-icons";

interface googleReview {
  author_name: string;
  author_url: string;
  language: string;
  original_language: string;
  profile_photo_url: string;
  rating: number;
  relative_time_description: string;
  text: string;
  time: number;
  translated: boolean;
}
export function Testimonials() {
  const { data: reviews } = useQuery(
    ["reviews"],
    async () => {
      const res = await fetch(
        "https://amadeussabra.com/amadeus/wp-json/google/reviews",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }

      const response: googleReview[] = await res.json(); // Logs raw response
      return response;
    }
  );
  return (
    <div className="flex flex-col w-screen justify-center items-center lg:pl-14 lg:pr-14 pl-12 pr-12 mt-10">
      <div className="flex flex-col gap-2 mb-10">
        <h3 className="sm:text-2xl font-medium text-xl font-montserrat text-center">
          COMMUNITY LOVE
        </h3>
        <p className="text-center text-sm opacity-80">
          Read what our loved ones are saying about Amadeus
          Beauty Center
        </p>
        <a
          href="https://www.google.com/search?q=amadeus+sabra&sca_esv=8214f2fdfa3e48e1&sxsrf=ADLYWIL8wC7GKiSRfi-Mg3XzDuLU4Y4x3w%3A1736803143297&ei=R4OFZ6nxEcOjkdUP18f0kAc&ved=0ahUKEwjpsJrzz_OKAxXDUaQEHdcjHXIQ4dUDCBA&uact=5&oq=amadeus+sabra&gs_lp=Egxnd3Mtd2l6LXNlcnAiDWFtYWRldXMgc2FicmEyCRAAGLADGAcYHjIJEAAYsAMYBxgeMg4QLhiABBiwAxjHARivATIIEAAYgAQYsAMyChAAGIAEGLADGAoyChAAGIAEGLADGAoyChAAGIAEGLADGAoyCBAAGIAEGLADMgcQABiwAxgeMgcQABiwAxgeSKEEUKsCWKsCcAF4AJABAJgBjgGgAY4BqgEDMC4xuAEDyAEA-AEBmAICoAKYAcICBxAjGLADGCfCAhAQLhiABBiwAxjHARgNGK8BwgIKEAAYgAQYsAMYDcICDBAAGIAEGLADGAoYDcICCRAAGLADGA0YHsICDhAAGIAEGLADGIYDGIoFmAMA4gMFEgExIECIBgGQBgqSBwMxLjGgB9AJ&sclient=gws-wiz-serp#"
          className="text-center underline text-[12px] font-medium mt-1 "
          target="_blank"
          rel="noreferrer"
        >
          VIEW ALL
        </a>
      </div>
      <Carousel className="w-full">
        <CarouselContent className="lg:ml-14">
          {reviews?.map((review, index) => (
            <CarouselItem
              key={review.time}
              className="lg:basis-1/3 md:1/2 basis-100"
            >
              <Card
                className={`lg:w-[400px] w-[275px]`}
                key={review.time}
              >
                <CardHeader className="flex flex-col gap-2">
                  <CardTitle>
                    {review.author_name}
                  </CardTitle>
                  <CardDescription className="flex gap-1">
                    {[...Array(review.rating)].map(
                      (_, i) => (
                        <StarFilledIcon
                          key={i}
                          color={"gold"}
                          className="size-4"
                        />
                      )
                    )}
                  </CardDescription>
                  <p className="text-neutral-600 text-[12px]">
                    {review.relative_time_description}
                  </p>
                </CardHeader>
                <CardContent className="line-clamp-3 lg:w-[370px] w-[280px] dark:text-neutral-200 text-neutral-900 text-sm ">
                  {review.text}
                </CardContent>
                <CardFooter className="flex justify-end mt-5">
                  <img
                    src="./google-logo.png"
                    alt="google-review"
                    className="size-8"
                  />
                </CardFooter>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="border-0" />
        <CarouselNext className="border-0" />
      </Carousel>
    </div>
  );
}
