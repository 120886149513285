import React from "react";
import { wooProduct } from "./productInterface";
import { WooCommerce } from "../../../src/lib/api";
import { useQuery } from "react-query";
import Wishlist from "../../../src/components/Wishlist";
import AddToCart from "../../../src/components/addToCart";
import { Card } from "../../../src/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "../../../src/components/ui/carousel";
import { Link, useNavigate } from "react-router-dom";
import { productCategoryFilter } from "../../../src/lib/utils";
import useWishlist from "../../../src/hooks/useWishlist";
import { Skeleton } from "../../../src/components/ui/skeleton";
import { Star } from "lucide-react";
import { useCookies } from "react-cookie";

export default function HomeProducts({
  title,
  categoryId,
  productId,
  similar,
}: {
  title: string;
  categoryId?: number;
  productId?: number;
  similar?: boolean;
}) {
  const nav = useNavigate();
  const { data: wishlist } = useWishlist();
  const [cookies] = useCookies(["userName"]);
  const wishlistArray = JSON.parse(
    window.localStorage.getItem("wishlistArray") as string
  );

  const { data: products, isLoading } = useQuery(
    ["homeProducts", categoryId, productId],
    async () => {
      const res: wooProduct[] = WooCommerce.get(
        `products/?per_page=100&page=1`
      )
        .then((response: any) => {
          if (similar) {
            const filteredProducts: wooProduct[] =
              response.data.filter((product: wooProduct) =>
                product.categories.some(
                  (category) =>
                    category.id === Number(categoryId) &&
                    product.id !== Number(productId)
                )
              );
            return filteredProducts;
          } else {
            return response.data.filter(
              (product: any) => product.featured
            );
          }
        })
        .catch((error: any) => {
          console.log("error getting products", error);
        });

      return res;
    }
  );

  const { data: productsRating } = useQuery(
    ["productsRating"],
    async () => {
      const res = WooCommerce.get("products/reviews")
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );

  const productsWithRatings = products?.map((product) => {
    // Filter reviews for the current product
    const productReviews = productsRating?.filter(
      (review: any) => review.product_id === product.id
    );
    // Calculate the average rating
    const averageRating =
      productReviews?.length > 0
        ? productReviews?.reduce(
            (sum: any, review: any) => sum + review.rating,
            0
          ) / productReviews?.length
        : 0; // Default to 0 if no reviews

    // Return product with the rating
    return {
      ...product,
      rating: averageRating,
    };
  });

  if (products && products?.length < 1) {
    return <></>;
  }
  return !isLoading ? (
    <div className="flex-col flex gap-5 h-full">
      <div className="flex flex-col gap-2 mb-10">
        <h3
          className={` text-center font-montserrat font-medium sm:text-2xl text-xl text-center  ${
            !similar && "lg:pl-5"
          }`}
        >
          {title}
        </h3>
        <p className="text-center text-sm ">
          Discover the iconic products that make our brand
          so unique in the professional haircare industry
        </p>
        <Link
          to="/shop"
          className="text-center underline text-sm font-medium"
        >
          VIEW ALL
        </Link>
      </div>
      <Carousel
        opts={{ loop: false, skipSnaps: true }}
        className="relative"
      >
        <CarouselContent className="h-full flex gap-5 ml-0">
          {productsWithRatings?.map(
            (product: wooProduct, index) => (
              <CarouselItem
                key={product.id}
                className="basis-auto p-0"
              >
                <Card
                  className=" rounded-none font-montserrat sm:w-56 w-48 snap-always snap-center justify-between relative flex flex-col gap-6 dark:border-neutral-800 dark:border border-0 shadow-none"
                  key={index}
                >
                  <div className="absolute top-4 right-4">
                    <Wishlist
                      productId={product.id}
                      productName={product.name}
                      wishlist={
                        wishlist ? wishlist : wishlistArray
                      }
                    />
                  </div>
                  {productCategoryFilter(
                    product?.categories
                  )
                    .slice(0, 1)
                    .map((category) => {
                      return (
                        <div
                          key={category.id}
                          className={
                            "w-full flex flex-col justify-between items-start gap-5"
                          }
                        >
                          <div
                            className="flex justify-center items-center w-full p-5 dark:bg-white cursor-pointer"
                            onClick={() =>
                              nav(
                                `/product/${category.id}/${category.name}/${product.id}/${product.slug}`
                              )
                            }
                          >
                            <img
                              src={
                                product?.images[0]?.src
                                  ? product.images[0]?.src
                                  : "/logo.png"
                              }
                              alt=""
                              className={
                                product?.images[0]?.src
                                  ? "w-full"
                                  : "dark:invert w-full"
                              }
                            />
                          </div>
                          <div className=" w-full flex flex-col gap-2 text-center">
                            <Link
                              to={`/product/${category.id}/${category.name}/${product.id}/${product.slug}`}
                              className=" line-clamp-2 w-full font-medium text-sm"
                            >
                              {product?.name?.toUpperCase()}
                            </Link>
                            <span className="text-neutral-400 italic text-[12px] capitalize hidden">
                              {category.name.toLowerCase()}
                            </span>
                            <span className="text-neutral-400 italic text-[12px] hidden">
                              {product.tags[0]?.name}
                            </span>
                            <p className="line-clamp-1 w-full dark:text-neutral-200 text-neutral-900 text-[10px]">
                              {product.description.length >
                              0
                                ? product?.description.slice(
                                    3,
                                    -5
                                  )
                                : "..."}
                            </p>
                            {cookies.userName && (
                              <div className="flex justify-center items-center gap-2">
                                {[...Array(5)].map(
                                  (_, i) => (
                                    <Star
                                      className="size-5"
                                      key={i}
                                      color="gold"
                                      fill={
                                        i < product.rating
                                          ? "gold"
                                          : "white dark:neutral-900"
                                      }
                                    />
                                  )
                                )}
                              </div>
                            )}
                            <p className="text-md font-bold grow mt-5">
                              {product?.price}.00$
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  <div className="w-full">
                    <AddToCart
                      id={String(product.id)}
                      quantity={"1"}
                      name={product.name}
                      categoryId={product.categories[0]?.id}
                      categoryName={
                        product.categories[0]?.name
                      }
                      hasVariations={
                        product?.attributes &&
                        product?.attributes.length > 0
                          ? true
                          : false
                      }
                    />
                  </div>
                </Card>
              </CarouselItem>
            )
          )}
        </CarouselContent>
        {products && products?.length > 6 && (
          <CarouselPrevious className="z-30 left-0 absolute h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        )}
        {products && products?.length > 6 && (
          <CarouselNext className="absolute z-30 right-0  h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        )}
      </Carousel>
    </div>
  ) : (
    <div className="flex justify-center gap-2 p-10 overflow-scroll no-scrollbar w-full">
      {Array.from({ length: 10 }).map((_, index) => (
        <div
          key={index}
          className="flex gap-2 flex-col items-center border w-56 h-96 justify-evenly p-5"
        >
          <Skeleton className="w-32 h-32 rounded-full" />
          <Skeleton className="w-32 h-4" />
          <Skeleton className="w-24 h-3" />
          <Skeleton className="w-24 h-2" />
          <Skeleton className="w-24 h-2" />
        </div>
      ))}
    </div>
  );
}
