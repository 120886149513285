import React from "react";
import { useCookies } from "react-cookie";
import { Button } from "./ui/button";
import {
  Check,
  Loader,
  ShoppingCartIcon,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { apiCall } from "../../src/lib/api";
import { toast } from "./ui/use-toast";
import { generateCartKey } from "../lib/utils";
import { cartItem } from "../pages/products/productInterface";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

///wp-json/cocart/v2/cart/add-item/
// const useApi = () => {
//   const [cookies] = useCookies(["cartKey", "userToken"]);

//   return (
//     endpoint: string,
//     params: Record<string, string>,
//     body: unknown
//   ) => {
//     const searchParams = new URLSearchParams();
//     Object.keys(params).forEach((key) => {
//       params[key] && searchParams.set(key, params[key]);
//     });
//     // console.log(searchParams, searchParams.toString());
//     return fetch(
//       `${apiCall}${endpoint + searchParams.toString()}`,
//       {
//         method: "post",
//         headers: {
//           "Content-type": "application/json",
//           Authorization: !cookies.userToken
//             ? ""
//             : `Bearer ${cookies.userToken}`,
//         },

//         body: JSON.stringify(body),
//       }
//     );
//   };
// };

export default function AddToCart({
  id,
  quantity,
  name,
  categoryId,
  categoryName,
  hasVariations,
  variations,
}: {
  id: string;
  name: string;
  categoryId: number;
  categoryName: string;
  quantity: string;
  hasVariations?: boolean;
  variations?: {};
}) {
  const [cookies, setCookie] = useCookies([
    "cartKey",
    "userToken",
  ]);
  // const callApi = useApi();
  const [loading, setLoading] = React.useState(false);
  const nav = useNavigate();
  const reactQuery = useQueryClient();
  const cartKey = generateCartKey();
  const { data: cartItems } = useQuery(
    ["cartItems"],
    async () => {
      const res = await fetch(
        !cookies.userToken
          ? `${apiCall}/wp-json/cocart/v2/cart?cart_key=${cookies.cartKey}`
          : `${apiCall}/wp-json/cocart/v2/cart`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: !cookies.userToken
              ? ""
              : `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }

      const items = await res.json();
      return items;
    },
    { enabled: !!cookies.cartKey }
  );

  const saveItemToCart = async () => {
    setLoading(true);
    const res = await fetch(
      `${apiCall}/wp-json/cocart/v2/cart/add-item/${
        cookies.userToken
          ? ""
          : `?cart_key=${
              !cookies.cartKey ? cartKey : cookies.cartKey
            }`
      }`,
      {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: !cookies.userToken
            ? ""
            : `Bearer ${cookies.userToken}`,
        },

        body: JSON.stringify({
          id: id,
          quantity: quantity,
        }),
      }
    );
    if (!res.ok) {
      setLoading(false);
      console.log(`HTTP error: ${res.status}`);
      toast({
        title: `Error adding to cart`,
        action: <ExclamationTriangleIcon />,
        className: "fixed top-0 left-0 sm:relative",
      });
    }
    const response = await res.json();
    if (!cookies.cartKey || !cookies.userToken) {
      setCookie("cartKey", response.cart_key, {
        path: "/",
        secure: false,
      });
    }
    const Key = await cookies.cartKey;
    if (Key) {
      reactQuery.refetchQueries(["cartItems"]);
      reactQuery.refetchQueries(["homeProducts"]);
      reactQuery.refetchQueries(["wooProducts"]);
      reactQuery.refetchQueries(["woo-products"]);
    }
    cookies.userToken &&
      reactQuery.refetchQueries(["LoggedInwishlist"]);
    reactQuery.refetchQueries(["singleProduct", id]);
    toast({
      title: `${name} added to cart`,
      action: <ShoppingCartIcon />,
      className: "fixed top-0 left-0 sm:relative",
    });

    setLoading(false);
    return response;
  };

  return cartItems &&
    cartItems.items?.some(
      (cartProduct: cartItem) =>
        cartProduct.id === Number(id)
    ) ? (
    <Button
      className="w-full flex justify-center items-center gap-1 rounded-none h-full"
      onClick={() => nav("/cart")}
    >
      <Check className="w-4 h-4 " />
      <span className="sm:text-sm text-[12px]">ADDED</span>
    </Button>
  ) : (
    <Button
      onClick={saveItemToCart}
      className="w-full flex justify-center items-start h-full gap-1 rounded-none"
      disabled={loading}
    >
      {loading ? (
        <Loader className="animate-spin duration-700 h-4 w-4" />
      ) : (
        <ShoppingCartIcon className="w-4 h-4" />
      )}
      <span className="sm:text-sm text-[12px]">
        ADD TO CART
      </span>
    </Button>
  );
}
