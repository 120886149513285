export interface servicesPriceList {
  id: number;
  serviceTitle: string;
  junior: number;
  senior: number;
  prive: number;
}

export const amadeusHairWomenServices: servicesPriceList[] =
  [
    {
      id: 1,
      serviceTitle: "BLOW-DRY SHORT HAIR",
      junior: 7,
      senior: 10,
      prive: 30,
    },
    {
      id: 2,
      serviceTitle: "BLOW-DRY MEDIUM HAIR",
      junior: 10,
      senior: 14,
      prive: 40,
    },
    {
      id: 3,
      serviceTitle: "BLOW-DRY LONG HAIR",
      junior: 14,
      senior: 17,
      prive: 50,
    },
    {
      id: 4,
      serviceTitle: "CURLING SHORT HAIR",
      junior: 10,
      senior: 14,
      prive: 45,
    },
    {
      id: 5,
      serviceTitle: "CURLING MEDIUM HAIR",
      junior: 14,
      senior: 17,
      prive: 50,
    },
    {
      id: 6,
      serviceTitle: "CURLING LONG HAIR",
      junior: 17,
      senior: 20,
      prive: 65,
    },
    {
      id: 7,
      serviceTitle: "HALF-UPDO",
      junior: 30,
      senior: 40,
      prive: 100,
    },
    {
      id: 8,
      serviceTitle: "UPDO",
      junior: 40,
      senior: 50,
      prive: 150,
    },
    {
      id: 9,
      serviceTitle: "RETRO SHORT HAIR",
      junior: 30,
      senior: 40,
      prive: 100,
    },
    {
      id: 10,
      serviceTitle: "RETRO LONG HAIR",
      junior: 40,
      senior: 50,
      prive: 150,
    },
    {
      id: 11,
      serviceTitle: "WET LOOK",
      junior: 30,
      senior: 35,
      prive: 80,
    },
    {
      id: 12,
      serviceTitle: "BRAIDING",
      junior: 20,
      senior: 30,
      prive: 100,
    },
    {
      id: 13,
      serviceTitle: "HAIRCUT",
      junior: 20,
      senior: 30,
      prive: 70,
    },
    {
      id: 14,
      serviceTitle: "FRINGE CUT",
      junior: 8,
      senior: 10,
      prive: 35,
    },
    {
      id: 15,
      serviceTitle: "SPLIT ENDS TRIMMING",
      junior: 10,
      senior: 20,
      prive: 50,
    },
    {
      id: 16,
      serviceTitle: "PRINCESS CUT",
      junior: 10,
      senior: 15,
      prive: 50,
    },
    {
      id: 17,
      serviceTitle: "FUCIO-DOSE TREATMENT KERASTASE",
      junior: 30,
      senior: 45,
      prive: 100,
    },
    {
      id: 18,
      serviceTitle: "FUSIO-SCRUB TREATMENT KERASTASE",
      junior: 20,
      senior: 30,
      prive: 60,
    },
    {
      id: 19,
      serviceTitle: "MIX-TREATMENT KERASTASE",
      junior: 45,
      senior: 65,
      prive: 130,
    },

    {
      id: 20,
      serviceTitle: "CHRONO-TUE KERASTASE",
      junior: 60,
      senior: 80,
      prive: 160,
    },
    {
      id: 21,
      serviceTitle: "EXTENTIONISTE-PROTOCOKERASTASE",
      junior: 35,
      senior: 45,
      prive: 100,
    },
    {
      id: 22,
      serviceTitle: "K-WATER TREATMENT KERASTASE",
      junior: 10,
      senior: 15,
      prive: 35,
    },
    {
      id: 23,
      serviceTitle: "FIBER-CLINIX SCHWARZKOPF TREATMENT",
      junior: 20,
      senior: 30,
      prive: 60,
    },
    {
      id: 24,
      serviceTitle: "FIBER-PLEX SCHWARZKOPF BONDING",
      junior: 25,
      senior: 35,
      prive: 60,
    },
    {
      id: 25,
      serviceTitle: "R2 TREATMENT",
      junior: 20,
      senior: 30,
      prive: 60,
    },
    {
      id: 26,
      serviceTitle: "DETOX",
      junior: 0.25,
      senior: 0,
      prive: 0,
    },
    {
      id: 27,
      serviceTitle: "HAIRCOLOR ROOTS",
      junior: 15,
      senior: 20,
      prive: 50,
    },
    {
      id: 28,
      serviceTitle: "HAIRCOLOR",
      junior: 40,
      senior: 60,
      prive: 120,
    },
    {
      id: 29,
      serviceTitle: "HAIRCOLOR ROOTS AMONIA FREE",
      junior: 18,
      senior: 25,
      prive: 50,
    },
    {
      id: 30,
      serviceTitle: "HAIRCOLOR AMONIA FREE",
      junior: 35,
      senior: 50,
      prive: 110,
    },
    {
      id: 31,
      serviceTitle: "BLEACHING HIGHLIGHT/LOWLIGHT",
      junior: 25,
      senior: 35,
      prive: 70,
    },
    {
      id: 32,
      serviceTitle: "TONER",
      junior: 25,
      senior: 35,
      prive: 70,
    },
    {
      id: 33,
      serviceTitle: "HIGHLIFT COLOR",
      junior: 45,
      senior: 90,
      prive: 130,
    },
    {
      id: 34,
      serviceTitle: "HAIRBOTOX GLOSSING TREATMENT",
      junior: 65,
      senior: 90,
      prive: 200,
    },
    {
      id: 35,
      serviceTitle: "HAIRKERATIN STRAIGHTENING TREATMENT",
      junior: 65,
      senior: 90,
      prive: 200,
    },
  ];

export const amadeusPrincessServices: servicesPriceList[] =
  [
    {
      id: 101,
      serviceTitle: "PRINCESS HALF UPDO",
      junior: 15,
      senior: 20,
      prive: 0,
    },
    {
      id: 102,
      serviceTitle: "PRINCESS UPDO",
      junior: 20,
      senior: 25,
      prive: 0,
    },
    {
      id: 103,
      serviceTitle: "PRINCESS BLOW DRY",
      junior: 3,
      senior: 5,
      prive: 0,
    },
  ];

export const amadeusMakeupLab: servicesPriceList[] = [
  {
    id: 201,
    serviceTitle: "REGULAR MAKEUP",
    junior: 40,
    senior: 85,
    prive: 200,
  },
  {
    id: 202,
    serviceTitle: "LASHES",
    junior: 10,
    senior: 15,
    prive: 45,
  },
  {
    id: 203,
    serviceTitle: "BROW-SHAPING",
    junior: 5,
    senior: 0,
    prive: 50,
  },
  {
    id: 204,
    serviceTitle: "UPPERLIP",
    junior: 2,
    senior: 0,
    prive: 0,
  },
  {
    id: 205,
    serviceTitle: "LASH-LIFTING",
    junior: 0,
    senior: 35,
    prive: 0,
  },
  {
    id: 206,
    serviceTitle: "BROW LAMINATION",
    junior: 0,
    senior: 35,
    prive: 0,
  },
  {
    id: 207,
    serviceTitle: "REJUVI TATTOO REMOVAL",
    junior: 0,
    senior: 0,
    prive: 200,
  },
  {
    id: 208,
    serviceTitle: "MICRO-BLADING EYEBROW TATTOO",
    junior: 0,
    senior: 0,
    prive: 350,
  },
  {
    id: 209,
    serviceTitle: "LIP CONTOUR/FILLING TATTOO",
    junior: 0,
    senior: 0,
    prive: 350,
  },
  {
    id: 210,
    serviceTitle: "BRIDAL HAIRSTYLE",
    junior: 0,
    senior: 350,
    prive: 700,
  },
  {
    id: 211,
    serviceTitle: "BRIDESMAID HAIRSTYLE",
    junior: 0,
    senior: 200,
    prive: 500,
  },
  {
    id: 212,
    serviceTitle: "ENGAGEMENT HAIRSTYLE",
    junior: 0,
    senior: 200,
    prive: 500,
  },
  {
    id: 213,
    serviceTitle: "BRIDAL MAKEUP",
    junior: 0,
    senior: 400,
    prive: 800,
  },
  {
    id: 214,
    serviceTitle: "BRIDESMAID MAKEUP",
    junior: 0,
    senior: 250,
    prive: 600,
  },
  {
    id: 215,
    serviceTitle: "ENGAGEMENT MAKEUP",
    junior: 0,
    senior: 250,
    prive: 600,
  },
];

export const amadeusNailLab: servicesPriceList[] = [
  {
    id: 301,
    serviceTitle: "Regular Manicure Without Gel Polish",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 302,
    serviceTitle: "Regular Pedicure Without Gel Polish",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 303,
    serviceTitle: "Regular Manicure With Gel Polish",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 304,
    serviceTitle: "Regular Pedicure With Gel Polish",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 305,
    serviceTitle: "Basic Design/Sticker Per Nail",
    junior: 1,
    senior: 0,
    prive: 0,
  },
  {
    id: 306,
    serviceTitle: "Regular Nail Polish",
    junior: 4,
    senior: 0,
    prive: 0,
  },
  {
    id: 307,
    serviceTitle: "French Nail Polish",
    junior: 6,
    senior: 0,
    prive: 0,
  },
  {
    id: 308,
    serviceTitle: "Regular Fake Nail",
    junior: 12,
    senior: 0,
    prive: 0,
  },
  {
    id: 309,
    serviceTitle: "Russian Manicure Without Gel Polish",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 310,
    serviceTitle: "Russian Pedicure Without Gel Polish",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 311,
    serviceTitle: "Russian Manicure With Gel Polish",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 312,
    serviceTitle: "Russian Pedicure With Gel Polish",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 313,
    serviceTitle: "Remove Gel Nail Polish",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 314,
    serviceTitle: "Gel Extension Refill",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 315,
    serviceTitle: "Gel Extension Refill/Nail",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 316,
    serviceTitle: "Remove Acrylic",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 317,
    serviceTitle: "Hands Scrubbing",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 318,
    serviceTitle: "Feet Scrubbing",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 319,
    serviceTitle: "Hands Paraffin",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 320,
    serviceTitle: "Feet Paraffin",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 321,
    serviceTitle:
      "Full Set Extensions Including Russian Manicure",
    junior: 40,
    senior: 0,
    prive: 0,
  },
];

export const amadeusSpaWomen: servicesPriceList[] = [
  {
    id: 401,
    serviceTitle: "Brow Wax",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 402,
    serviceTitle: "Neck Wax",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 403,
    serviceTitle: "Ear Wax",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 404,
    serviceTitle: "Forehead Wax",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 405,
    serviceTitle: "Cheek Wax",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 406,
    serviceTitle: "Full Face Wax",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 407,
    serviceTitle: "Full Body Wax",
    junior: 35,
    senior: 0,
    prive: 0,
  },
  {
    id: 408,
    serviceTitle: "Extra Full Body Wax",
    junior: 40,
    senior: 0,
    prive: 0,
  },
  {
    id: 409,
    serviceTitle: "Half Arms Wax",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 410,
    serviceTitle: "Full Arms Wax",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 411,
    serviceTitle: "Half Legs Wax",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 412,
    serviceTitle: "Full Legs Wax",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 413,
    serviceTitle: "Under Arms Wax",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 414,
    serviceTitle: "Stomach Line Wax",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 415,
    serviceTitle: "Full Stomach Wax",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 416,
    serviceTitle: "Full Back Wax",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 417,
    serviceTitle: "Full Face Threading",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 418,
    serviceTitle: "Brazilian Wax",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 419,
    serviceTitle: "Bikini Wax",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 420,
    serviceTitle: "Hands Massage 15 Min",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 421,
    serviceTitle: "Feet Massage 15 Min",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 422,
    serviceTitle: "Back & Neck Massage 60 Min",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 423,
    serviceTitle: "Aromatherapy Massage",
    junior: 30,
    senior: 0,
    prive: 0,
  },
  {
    id: 424,
    serviceTitle: "Deep Tissue Massage 60 Min",
    junior: 35,
    senior: 0,
    prive: 0,
  },
  {
    id: 425,
    serviceTitle: "Hot Stone Massage",
    junior: 35,
    senior: 0,
    prive: 0,
  },
  {
    id: 426,
    serviceTitle: "Full Body-Scrubbing 25 Min",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 427,
    serviceTitle: "Full Body-Exfoliation 25 Min",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 428,
    serviceTitle: "Massage for Drainage 60 Min",
    junior: 40,
    senior: 0,
    prive: 0,
  },
  {
    id: 429,
    serviceTitle: "Cellulite Massage-Stomach 20 Min",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 430,
    serviceTitle: "Cellulite Massage-Full Legs 30 Min",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 431,
    serviceTitle: "Cellulite Massage-Stomach & Legs 50 Min",
    junior: 30,
    senior: 0,
    prive: 0,
  },
  {
    id: 432,
    serviceTitle: "Face Massage 20 Min",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 433,
    serviceTitle: "Contouring Legs 30 Min",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 434,
    serviceTitle: "Contouring Stomach & Back 20 Min",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 435,
    serviceTitle: "Contouring Arms 20 Min",
    junior: 18,
    senior: 0,
    prive: 0,
  },
  {
    id: 436,
    serviceTitle: "Full Contouring 60 Min",
    junior: 35,
    senior: 0,
    prive: 0,
  },
  {
    id: 437,
    serviceTitle: "Madero 10 Sessions Package",
    junior: 250,
    senior: 0,
    prive: 0,
  },
  {
    id: 438,
    serviceTitle: "Neck, Face & Scalp Massage 45 Min",
    junior: 25,
    senior: 0,
    prive: 0,
  },
  {
    id: 439,
    serviceTitle: "Scalp Massage 20 Min",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 440,
    serviceTitle: "Swedish Massage 60 Min",
    junior: 35,
    senior: 0,
    prive: 0,
  },
];

export const amadeusMenHairServices: servicesPriceList[] = [
  {
    id: 601,
    serviceTitle: "Haircut",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 602,
    serviceTitle: "Beard Trimming",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 603,
    serviceTitle: "Beard Shaping & Styling",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 604,
    serviceTitle: "Hair Styling",
    junior: 5,
    senior: 0,
    prive: 0,
  },
  {
    id: 605,
    serviceTitle: "Prince-Cut",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 606,
    serviceTitle: "Face Mask",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 607,
    serviceTitle: "Face Scrubbing",
    junior: 3,
    senior: 0,
    prive: 0,
  },
  {
    id: 608,
    serviceTitle: "Fusio-Dose Treatment Kerastase",
    junior: 30,
    senior: 0,
    prive: 0,
  },
  {
    id: 609,
    serviceTitle: "Fusio-Scrub Treatment Kerastase",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 610,
    serviceTitle: "Mix-Treatment Kerastase",
    junior: 45,
    senior: 0,
    prive: 0,
  },
  {
    id: 611,
    serviceTitle: "Chrono-Rituel Kerastase",
    junior: 60,
    senior: 0,
    prive: 0,
  },
  {
    id: 612,
    serviceTitle: "Extentioniste-Protocol Kerastase",
    junior: 35,
    senior: 0,
    prive: 0,
  },
  {
    id: 613,
    serviceTitle: "K-Water Treatment Kerastase",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 614,
    serviceTitle: "Fiber-Clinx Schwarzkopf Treatment",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 615,
    serviceTitle:
      "Fiber-Plex Schwarzkopf Bonding Treatment",
    junior: 25,
    senior: 0,
    prive: 0,
  },
  {
    id: 616,
    serviceTitle: "Hair Color Roots",
    junior: 35,
    senior: 0,
    prive: 0,
  },
  {
    id: 617,
    serviceTitle: "Hair Color",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 618,
    serviceTitle: "Hair Color Ammonia Free",
    junior: 40,
    senior: 0,
    prive: 0,
  },
  {
    id: 619,
    serviceTitle: "Hair Color Ammonia Free Bleaching",
    junior: 18,
    senior: 0,
    prive: 0,
  },
  {
    id: 620,
    serviceTitle: "Highlight/Lowlight",
    junior: 0.35,
    senior: 0,
    prive: 0,
  },
  {
    id: 621,
    serviceTitle: "Highlift Color",
    junior: 45,
    senior: 0,
    prive: 0,
  },
  {
    id: 622,
    serviceTitle: "Hair-Botox Starting",
    junior: 65,
    senior: 0,
    prive: 0,
  },
  {
    id: 623,
    serviceTitle: "Hair-Botox Glossing Treatment",
    junior: 0.6,
    senior: 0,
    prive: 0,
  },
  {
    id: 624,
    serviceTitle: "Hair-Keratin Starting",
    junior: 65,
    senior: 0,
    prive: 0,
  },
  {
    id: 625,
    serviceTitle: "Hair Keratin Straightening Treatment",
    junior: 0.6,
    senior: 0,
    prive: 0,
  },
];

export const amadeusSpaMenServices: servicesPriceList[] = [
  {
    id: 701,
    serviceTitle: "Brow Wax",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 702,
    serviceTitle: "Neck Wax",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 703,
    serviceTitle: "Ear Wax",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 704,
    serviceTitle: "Nose Wax",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 705,
    serviceTitle: "Forehead Wax",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 706,
    serviceTitle: "Cheek Wax",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 707,
    serviceTitle: "Full Face Wax",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 708,
    serviceTitle: "Full Face Threading",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 709,
    serviceTitle: "Regular Manicure",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 710,
    serviceTitle: "Regular Pedicure",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 711,
    serviceTitle: "Russian Manicure - Men",
    junior: 15,
    senior: 0,
    prive: 0,
  },
  {
    id: 712,
    serviceTitle: "Russian Pedicure - Men",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 713,
    serviceTitle: "Hands Scrubbing",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 714,
    serviceTitle: "Feet Scrubbing",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 715,
    serviceTitle: "Hands Paraffin",
    junior: 8,
    senior: 0,
    prive: 0,
  },
  {
    id: 716,
    serviceTitle: "Feet Paraffin",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 717,
    serviceTitle: "Hands Massage 15 Min",
    junior: 7,
    senior: 0,
    prive: 0,
  },
  {
    id: 718,
    serviceTitle: "Feet Massage 15 Min",
    junior: 10,
    senior: 0,
    prive: 0,
  },
  {
    id: 719,
    serviceTitle: "Back & Neck Massage 30 Min",
    junior: 20,
    senior: 0,
    prive: 0,
  },
  {
    id: 720,
    serviceTitle: "Swedish Massage 50 Min",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 721,
    serviceTitle: "Aromatherapy Massage 50 Min",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 722,
    serviceTitle: "Deep Tissue Massage 50 Min",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 723,
    serviceTitle: "Reflexology Massage 50 Min",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 724,
    serviceTitle: "Hot Stone",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 725,
    serviceTitle: "Full Body-Scrubbing 15 Min",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 726,
    serviceTitle: "Full Body-Exfoliation 15 Min",
    junior: 0,
    senior: 0,
    prive: 0,
  },
  {
    id: 727,
    serviceTitle: "SOLARIUM PER 10 MIN",
    junior: 5,
    senior: 0,
    prive: 0,
  },
];

export const amadeusServices = [
  {
    id: 1001,
    service: "Hair",
    image: "/hair.jpeg",
    link: "/services/lebanon/lebanonWomen/hair",
  },
  {
    id: 1002,
    service: "Bride",
    image: "/princess.png",
    link: "/services/lebanon/lebanonWomen/princess",
  },
  {
    id: 1003,
    service: "Makeup",
    image: "makeup.jpeg",
    link: "/services/lebanon/lebanonWomen/makeup",
  },
  {
    id: 1004,
    service: "Nail",
    image: "nail.jpeg",
    link: "/services/lebanon/lebanonWomen/nail",
  },
  {
    id: 1005,
    service: "SPA",
    image: "spa.jpeg",
    link: "/services/lebanon/lebanonWomen/spa",
  },
  {
    id: 1006,
    service: "Men",
    image: "/hair-men.jpeg",
    link: "/services/lebanon/lebanonMen/hair",
  },
];
