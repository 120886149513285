import React from "react";
import { wooCategory } from "../../lib/interfaces";
import { useQuery } from "react-query";
import { WooCommerce } from "../../../src/lib/api";
import { Link, useNavigate } from "react-router-dom";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../src/components/ui/carousel";
import { Skeleton } from "../../../src/components/ui/skeleton";
import { Button } from "../../../src/components/ui/button";

export default function HomeCollections({
  collectionId,
  title,
}: {
  collectionId: number;
  title: string;
}) {
  const nav = useNavigate();
  const [hoveredIndex, setHoveredIndex] = React.useState<
    number | null
  >(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const { data: categories } = useQuery(
    ["wooCategories"],
    async () => {
      const res: wooCategory[] = WooCommerce.get(
        "products/categories/?per_page=100"
      )
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );
  return (
    <div className="flex flex-col gap-2 pl-0 relative">
      <div className="flex flex-col gap-2 mb-10">
        <h3 className="sm:text-3xl font-medium text-xl font-montserrat text-center">
          DISCOVER {title.toLocaleUpperCase()}
        </h3>
        <p className="text-center text-sm opacity-80">
          Discover the iconic products that make our brand
          so unique in the professional haircare industry
        </p>
        <Link
          to={`/shop/category/${collectionId}/${title}`}
          className="text-center underline text-[12px] font-medium mt-1 "
        >
          VIEW ALL
        </Link>
      </div>
      <Carousel opts={{ loop: false, skipSnaps: true }}>
        <CarouselContent className="flex gap-2 ml-0">
          {!categories ? (
            <div className="h-[300px] flex justify-center items-center gap-2 p-10">
              {Array.from({ length: 10 }).map(
                (_, index) => (
                  <div
                    key={index}
                    className="flex gap-2 flex-col justify-end items-center border h-full p-4"
                  >
                    <Skeleton className="w-64 h-3" />
                  </div>
                )
              )}
            </div>
          ) : (
            categories
              ?.filter(
                (cat) =>
                  cat.parent === collectionId &&
                  cat.id !== 424 &&
                  cat.id !== 435 &&
                  cat.id !== 408 &&
                  cat.id !== 380 &&
                  cat.id !== 399 &&
                  cat.id !== 441 &&
                  cat.id !== 446 &&
                  cat.id !== 447 &&
                  cat.id !== 453 &&
                  cat.id !== 454 &&
                  cat.id !== 456 &&
                  cat.id !== 462 &&
                  cat.id !== 463 &&
                  cat.id !== 465 &&
                  cat.id !== 439 &&
                  cat.id !== 460 &&
                  cat.id !== 468 &&
                  cat.id !== 474 &&
                  cat.id !== 480 &&
                  cat.id !== 421
              )
              .map((category, index) => (
                <CarouselItem
                  key={category.id}
                  className={"sm:basis-80 basis-1/2 p-0"}
                  onMouseEnter={() =>
                    handleMouseEnter(index)
                  }
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <div
                    data-aos="fade-up"
                    data-aos-delay={`${index * 100}`}
                    data-aos-duration={`${index * 40}`}
                    className=" border border-neutral-200 dark:border-b-neutral-800 relative cursor-pointer h-full"
                    onClick={() =>
                      nav(
                        `/shop/category/${
                          category.id
                        }/${encodeURIComponent(
                          category.name
                        )}`
                      )
                    }
                  >
                    {" "}
                    {hoveredIndex === index && (
                      <div
                        key={index}
                        className={`absolute w-full bg-neutral-400 z-30 h-full opacity-20`}
                      ></div>
                    )}
                    <div className="flex justify-center items-center h-full">
                      {!categories || !category.image ? (
                        <div
                          key={index}
                          className="flex flex-col items-center justify-center space-y-4 h-[300px]"
                        >
                          <Skeleton className="w-24 h-2" />
                          <Skeleton className="w-32 h-3" />
                        </div>
                      ) : (
                        <img
                          src={category.image?.src}
                          alt="w-full"
                          className="h-full w-full"
                        />
                      )}
                    </div>
                    {categories ? (
                      <Button className="absolute bottom-0 text-black shadow-xl w-full rounded-none p-0 font-montserrat sm:text-[16px] text-[12px] font-[500] bg-white opacity-80">
                        {category.name.toLocaleUpperCase()}
                      </Button>
                    ) : (
                      <div className="absolute bottom-0 p-5 w-full flex flex-col dark:text-neutral-900">
                        <Skeleton className="w-48 h-4" />
                      </div>
                    )}
                  </div>
                </CarouselItem>
              ))
          )}
        </CarouselContent>
        <CarouselPrevious className="z-30 left-0 absolute h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        <CarouselNext className="absolute z-30 right-0  h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
      </Carousel>
    </div>
  );
}
