import React from "react";
import { wooProduct } from "./productInterface";
import { useNavigate } from "react-router-dom";
import AddToCart from "../../../src/components/addToCart";
import {
  Card,
  CardHeader,
  CardContent,
  CardDescription,
  CardFooter,
} from "../../../src/components/ui/card";
import { Skeleton } from "../../../src/components/ui/skeleton";
import Wishlist from "../../components/Wishlist";
import MyLoader from "../../../src/components/MyLoader";
import { productCategoryFilter } from "../../../src/lib/utils";
import useWishlist from "../../../src/hooks/useWishlist";
import { Star } from "lucide-react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { WooCommerce } from "../../../src/lib/api";

export default function Products({
  products,
  title,
}: {
  products: wooProduct[];
  title?: string;
}) {
  const nav = useNavigate();
  const { data: wishlist } = useWishlist();
  const [cookies] = useCookies(["userName"]);
  const { data: productsRating } = useQuery(
    ["productsRating"],
    async () => {
      const res = WooCommerce.get("products/reviews")
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );

  const productsWithRatings = products?.map((product) => {
    // Filter reviews for the current product
    const productReviews = productsRating?.filter(
      (review: any) => review.product_id === product.id
    );
    // Calculate the average rating
    const averageRating =
      productReviews?.length > 0
        ? productReviews?.reduce(
            (sum: any, review: any) => sum + review.rating,
            0
          ) / productReviews?.length
        : 0; // Default to 0 if no reviews

    // Return product with the rating
    return {
      ...product,
      rating: averageRating,
    };
  });

  return (
    <div className="flex-col flex gap-5">
      <h2 className="font-montserrat font-medium sm:text-2xl text-md text-center lg:text-left">
        {title}
      </h2>
      <div
        className={
          "flex gap-y-10 sm:justify-start gap-x-1 sm:gap-x-10 no-scrollbar sm:overflow-hidden flex-wrap w-full"
        }
      >
        {!products ? (
          <div className="w-full h-full">
            <MyLoader />
          </div>
        ) : (
          productsWithRatings?.map(
            (product: wooProduct, index) => (
              <Card
                className="rounded-none grow basis-1/5 font-montserrat w-[49%] sm:w-[46%] lg:w-60 snap-always snap-center justify-between relative flex flex-col gap-1 border-0 shadow-none dark:border-neutral-800 dark:border "
                key={index}
              >
                <div className="absolute top-4 right-4">
                  <Wishlist
                    productId={product.id}
                    productName={product.name}
                    wishlist={wishlist}
                  />
                </div>

                {productCategoryFilter(product?.categories)
                  .slice(0, 1)
                  .map((category) => (
                    <div
                      onClick={() =>
                        nav(
                          `/product/${category.id}/${category.name}/${product.id}/${product.slug}`
                        )
                      }
                      key={category.id}
                      className={
                        product.images
                          ? "w-full flex justify-center items-center sm:p-5 p-1 dark:bg-white grow h-1/4 cursor-pointer"
                          : "w-full flex justify-center items-center sm:p-5 p-1 dark:bg-neutral-900 grow h-1/4 cursor-pointer"
                      }
                    >
                      <img
                        src={
                          product?.images[0]?.src
                            ? product?.images[0]?.src
                            : "/logo.png"
                        }
                        alt=""
                        className={
                          product?.images[0]?.src
                            ? "w-44"
                            : "dark:invert w-44"
                        }
                      />
                    </div>
                  ))}

                <CardHeader
                  className=" w-full flex flex-col p-0 m-0 flex flex-col gap-2 sm:pl-3 sm:pr-3 pl-1 pr-1 items-start cursor-pointer"
                  onClick={() =>
                    nav(
                      `/product/${product.categories[1].id}/${product.categories[1].name}/${product.id}/${product.slug}`
                    )
                  }
                >
                  {products ? (
                    <p className="line-clamp-2 w-full font-medium text-sm sm:text-md text-center">
                      {product?.name.toUpperCase()}
                    </p>
                  ) : (
                    <Skeleton className="h-4 w-32" />
                  )}
                </CardHeader>
                <CardContent className="sm:pl-3 sm:pr-3 pl-1 pr-1 flex flex-col gap-1 pb-0 ">
                  {productCategoryFilter(
                    product?.categories
                  )
                    .slice(0, 1)
                    .map((category) => (
                      <span
                        key={category.id}
                        className="text-neutral-400 text-[12px] italic truncate hidden"
                      >
                        {category.name}
                      </span>
                    ))}
                  <span className="text-neutral-400 italic text-[12px] truncate hidden">
                    {product.tags[0]?.name}
                  </span>
                  <CardDescription className="truncate w-full dark:text-neutral-200 text-neutral-900 text-[12px] text-center">
                    {product.description.length > 0
                      ? product?.description.slice(3, -5)
                      : "..."}
                  </CardDescription>
                  {cookies.userName && (
                    <div className="flex justify-center items-center gap-2 mt-2">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          className="size-5"
                          key={i}
                          color="gold"
                          fill={
                            i < product.rating
                              ? "gold"
                              : "white dark:neutral-900"
                          }
                        />
                      ))}
                    </div>
                  )}
                  <p className="text-md font-bold grow text-center mt-5 text-neutral-600 dark:text-neutral-100">
                    {product?.price}.00$
                  </p>
                </CardContent>
                <CardFooter className="sm:p-3 p-1">
                  <AddToCart
                    id={String(product.id)}
                    quantity={"1"}
                    name={product.name}
                    categoryId={product.categories[0]?.id}
                    categoryName={
                      product.categories[0]?.name
                    }
                    hasVariations={
                      product?.attributes &&
                      product?.attributes.length > 0
                        ? true
                        : false
                    }
                  />
                </CardFooter>
              </Card>
            )
          )
        )}
      </div>
    </div>
  );
}
