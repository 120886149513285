import React from "react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Link, useNavigate } from "react-router-dom";
import { ProfileData, wpUser } from "@/src/lib/interfaces";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useCookies } from "react-cookie";
import { LoaderIcon } from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import { apiCall } from "../../../src/lib/api";
import EyePassword from "../../../src/components/eyePassword";

export default function Login() {
  const { toast } = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies([
    "userToken",
    "userId",
    "userName",
    "userEmail",
  ]);
  const [user, setUser] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const nav = useNavigate();

  async function tryLogin(e: any) {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await fetch(
        `${apiCall}/wp-json/jwt-auth/v1/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: user,
            password: pass,
          }),
        }
      );

      const res: wpUser = await response.json();

      if (res?.token) {
        // router.push(`/profile`);
        !cookies.userToken &&
          setCookie("userToken", res.token, {
            path: "/",
            secure: false,
          });

        const fetchProfile = async () => {
          try {
            const response = await fetch(
              `${apiCall}/wp-json/wp/v2/users/me?context=edit`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${res.token}`,
                },
              }
            );
            setLoader(false);
            const data: ProfileData = await response.json();
            !cookies.userId && setCookie("userId", data.id);
            setCookie("userName", data.username, {
              path: "/",
              secure: false,
            });
            setCookie("userEmail", data.email, {
              path: "/",
              secure: false,
            });
            nav("/profile");
            return data; // Set profile data to state
          } catch (error) {
            console.error("Error getting profile", error);
          }
        };
        fetchProfile();
      }
      if (res?.data?.status === 403) {
        toast({
          title: "Error Login",
          description: "Wrong username or password!",
          variant: "destructive",
          action: <ExclamationTriangleIcon />,
          className:
            "fixed top-0 left-0 sm:relative rounded-none",
        });
        setLoader(false);
      }

      if (!res) {
        setLoader(false);
      }
      return res;
    } catch (error: any) {
      console.error("Error", error);
      toast({
        title: "Server Error",
        description:
          "Please check your internet connection",
        variant: "destructive",
        action: <ExclamationTriangleIcon />,
        className: "fixed top-0 left-0 sm:relative",
      });
      setLoader(false);
    }
  }
  React.useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter"
      ) {
        tryLogin(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [user, pass]);
  return (
    <div className="w-screen h-dvh flex justify-center items-center flex-col p-10 ">
      <form
        className="shadow-2xl sm:w-96 w-full p-10 flex flex-col gap-2 items-center"
        onSubmit={(e) => tryLogin(e)}
      >
        <img
          src="/logo-aaa.png"
          alt="logo"
          width={50}
          className="dark:invert mb-5"
        />
        <h1 className="font-bold text-center text-xl mb-5">
          SIGN IN
        </h1>
        <Input
          name="username"
          required
          autoComplete="username"
          type="text"
          placeholder="Email"
          className="text-neutral-900 focus:outline-none dark:text-neutral-200 rounded-none"
          value={user}
          onChange={(e) => {
            setUser(e.target.value);
          }}
        />
        <EyePassword value={pass} setValue={setPass} />
        <Button
          className="mt-1 border w-full rounded-none"
          type="submit"
        >
          {loader ? (
            <LoaderIcon className="animate-spin" />
          ) : (
            `SIGN IN`
          )}
        </Button>
        <div className="dark:text-neutral-200 mt-5 text-sm ">
          {`Don't have an account? `}
          <Link className="hover:underline" to={"/signup"}>
            SIGN UP
          </Link>
        </div>
        <div className="dark:text-neutral-200 mt-5 text-sm ">
          {`Lost your password? `}
          <Link className="hover:underline" to={"/reset"}>
            RESET
          </Link>
        </div>
      </form>
    </div>
  );
}
