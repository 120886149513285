import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb";

export function Breadcrumbs({
  categoryId,
  categoryName,
  tagId,
  tagName,
  type,
}: {
  categoryId?: string;
  categoryName?: string;
  tagId?: string;
  tagName?: string;
  type?: string;
}) {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <Link to="/" className=" text-[10px] capitalize">
            HOME
          </Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <Link
            to="/shop"
            className=" text-[10px] capitalize"
          >
            SHOP
          </Link>
        </BreadcrumbItem>
        {type !== "shop" && <BreadcrumbSeparator />}
        {tagName !== undefined ? (
          <BreadcrumbItem>
            <p className=" text-[10px] capitalize">
              {tagName.toLocaleUpperCase()}
            </p>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem>
            {" "}
            <Link
              to={`/shop/category/${categoryId}/${categoryName}`}
              className=" text-[10px] capitalize"
            >
              {categoryName?.toLocaleUpperCase()}
            </Link>
          </BreadcrumbItem>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
