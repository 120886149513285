import React from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { wooOrder } from "./orderInterface";
import { Card } from "../../../src/components/ui/card";
import { Badge } from "../../../src/components/ui/badge";
import { CreditCard, DollarSign } from "lucide-react";
import { Separator } from "../../../src/components/ui/separator";
export default function OrderPreview({
  order,
}: {
  order: wooOrder;
}) {
  return (
    <Dialog key={order.id}>
      <DialogTrigger asChild>
        <Card className="p-2 flex justify-between rounded-none items-center w-full hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <div className="flex justify-between items-center gap-2 w-full">
            <h2 className="w-full text-sm">#{order.id}</h2>

            <p className="text-sm text-neutral-600 w-full hidden sm:block">
              {order.date_created.slice(0, 10)}
            </p>
            <p className="text-sm font-bold w-full">
              {order.status}
            </p>
            <Badge variant="secondary" className="w-32">
              {order.total}$
            </Badge>
          </div>
        </Card>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Order #{order.id}</DialogTitle>
          <DialogDescription>
            Date: {order.date_created}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-scroll no-scrollbar  max-h-[500px] ">
          <div className="grid gap-1">
            <div className="font-medium">Order Details</div>
            <ul className="grid gap-1">
              {order.line_items.map((item) => (
                <li
                  className="flex items-center justify-between"
                  key={item.id}
                >
                  <span className="text-muted-foreground text-sm w-4/6">
                    {item.name + " x" + item.quantity}
                  </span>
                  <span>{item.price}.00$</span>
                </li>
              ))}
            </ul>
            <Separator className="my-2" />
            <ul className="grid gap-1">
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  Shipping
                </span>
                <span>{order?.shipping_total}$</span>
              </li>
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  Tax
                </span>
                <span>{order.total_tax}$</span>
              </li>
              <li className="flex items-center justify-between font-medium">
                <span className="text-muted-foreground">
                  Total
                </span>
                <span>{order.total}$</span>
              </li>
            </ul>
          </div>
          <Separator className="my-2" />
          <div className="grid grid-cols-2 gap-4">
            <div className="grid gap-3">
              <div className="font-medium">
                Shipping Information
              </div>
              <address className="grid gap-0.5 not-italic text-muted-foreground">
                <span>
                  {order.billing.first_name +
                    " " +
                    order.billing.last_name}
                </span>
                <span>{order.billing.city}</span>
                <span>{order.billing.country}</span>
              </address>
            </div>
            <div className="grid auto-rows-max gap-3">
              <div className="font-medium">
                Billing Information
              </div>
              {order.shipping.first_name ? (
                <address className="grid gap-0.5 not-italic text-muted-foreground">
                  <span>
                    {order.shipping.first_name +
                      " " +
                      order.shipping.last_name}
                  </span>
                  <span>{order.shipping.city}</span>
                  <span>{order.shipping.country}</span>
                </address>
              ) : (
                <address className="text-muted-foreground">
                  Same as Billing
                </address>
              )}
            </div>
          </div>
          <Separator className="my-4" />
          <div className="grid gap-3">
            <div className="font-medium">
              Customer Information
            </div>
            <dl className="grid gap-1">
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">
                  Customer
                </dt>
                <dd>
                  {order.billing.first_name +
                    " " +
                    order.billing.last_name}
                </dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">
                  Email
                </dt>
                <dd>
                  <a href={`mailto:${order.billing.email}`}>
                    {order.billing.email}
                  </a>
                </dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">
                  Phone
                </dt>
                <dd>
                  <a href="tel:">{order.billing.phone}</a>
                </dd>
              </div>
            </dl>
          </div>
          <Separator className="my-4" />
          <div className="grid gap-3">
            <div className="font-medium">
              Payment Information
            </div>
            <dl className="grid gap-3">
              {order.payment_method === "card" ? (
                <div className="flex items-center justify-between">
                  <dt className="flex items-center gap-1 text-muted-foreground">
                    <CreditCard className="h-4 w-4" />
                    Visa
                  </dt>
                  <dd>**** **** **** 4532</dd>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <dt className="flex items-center gap-1 text-muted-foreground">
                    <DollarSign className="h-4 w-4" />
                    Cash
                  </dt>
                  <dd>On Delivery</dd>
                </div>
              )}
            </dl>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" className="rounded-none">
              CLOSE
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
