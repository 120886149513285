import { Button } from "../../../src/components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../../src/components/ui/card";
import { useNavigate } from "react-router-dom";
import { cartItem } from "../products/productInterface";
import Coupons from "./coupons";
import { LoaderIcon } from "lucide-react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { apiCall, WooCommerce } from "../../../src/lib/api";
import MyLoader from "../../../src/components/MyLoader";
import React from "react";
import { toast } from "../../../src/components/ui/use-toast";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
export default function CartDetails({
  checkout,
  order,
  loading,
}: {
  checkout: boolean;
  order?: any;
  loading?: boolean;
}) {
  const [cookies] = useCookies(["userToken", "cartKey"]);
  const [loader, setLoader] = React.useState(false);
  const nav = useNavigate();
  const { data: cart, isLoading } = useQuery(
    ["cartItems"],
    async () => {
      const key = await cookies.cartKey;
      const res = await fetch(
        !cookies.userToken
          ? `${apiCall}/wp-json/cocart/v2/cart/?cart_key=${key}`
          : `${apiCall}/wp-json/cocart/v2/cart/`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: !cookies.userToken
              ? ""
              : `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }
      const response = await res.json();
      return response;
    },
    { enabled: !!cookies.cartKey || !!cookies.userToken }
  );
  async function createOrder(e: any) {
    setLoader(true);
    if (
      order.billing.firstName === "" ||
      order.billing.lastName === "" ||
      order.billing.email === "" ||
      order.billing.phone === "" ||
      order.billing.country === "" ||
      order.billing.city === "" ||
      order.billing.town === "" ||
      order.billing.obuilding === ""
    ) {
      toast({
        title: "Required Fields",
        description: "Please fill your information",
        action: <ExclamationTriangleIcon />,
      });
      setLoader(false);
      return;
    }

    try {
      await WooCommerce.post("orders", order)
        .then((response: any) => {
          setLoader(false);
          nav(`/order/${response.data.id}`);
        })
        .catch((error: any) => {
          setLoader(false);
          console.log(
            "error creating order",
            error.response.data
          );
        });
    } catch (err) {
      setLoader(false);
      console.log("error order", err);
    }
  }

  return isLoading ? (
    <MyLoader />
  ) : (
    <>
      <div className="w-full rounded-none flex flex-col justify-between">
        <CardHeader className="p-0 mb-5">
          <CardTitle className="border-b pb-2 text-xl font-montserrat font-medium">
            CART DETAILS
          </CardTitle>
        </CardHeader>
        <div className="flex flex-col gap-2 justify-between  font-montserrat grow">
          <div className="flex flex-col gap-10">
            {cart?.items?.map((item: cartItem) => (
              <div
                key={item.item_key}
                className="flex gap-5 border-b text-sm justify-between items-center w-full"
              >
                <p className="w-12">
                  {item.quantity.value} x
                </p>
                <p className="grow text-[12px] w-full">
                  {item.name.toLocaleUpperCase()}
                </p>
                <p className="">
                  {item.totals.subtotal || "0"}$
                </p>
              </div>
            ))}
          </div>
          <div className="border-b pb-2 flex gap-10 flex-col justify-between">
            <div className="flex justify-between mt-5">
              <p className="font-medium text-md">
                SUBTOTAL
              </p>
              <p className="font-medium">
                {loading ? (
                  <LoaderIcon className="animate-spin w-4 h-4" />
                ) : (
                  `${cart?.totals?.subtotal || "0"}$`
                )}
              </p>
            </div>
            <Coupons total={cart?.totals?.subtotal} />
          </div>
        </div>
        <CardFooter className="flex justify-between p-0">
          {checkout && order ? (
            <Button
              className="w-full rounded-none"
              type="submit"
              onClick={() => createOrder(order)}
            >
              {loader ? (
                <LoaderIcon className="animate-spin" />
              ) : (
                "ORDER NOW"
              )}
            </Button>
          ) : (
            <Button
              className="w-full rounded-none"
              type="submit"
              onClick={() => nav("/checkout")}
            >
              PROCEED TO CHECKOUT
            </Button>
          )}
        </CardFooter>
      </div>
    </>
  );
}
