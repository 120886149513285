import React from "react";
import {
  amadeusHairWomenServices,
  amadeusMakeupLab,
  amadeusNailLab,
  amadeusPrincessServices,
  amadeusSpaWomen,
} from "../priceList";

import { Button } from "../../../../src/components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { WomenServices } from "../women/women";
import { womenColumns } from "../women/womenColumns";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
export function LebanonWomen() {
  const nav = useNavigate();
  const tab = useParams().tab;

  return (
    <div className="sm:p-32 p-5 mt-10">
      <h2 className="text-xl sm:text-2xl font-montserrat font-medium mb-5">
        AMADEUS WOMEN
      </h2>
      <Tabs
        defaultValue={tab || "hair"}
        value={tab}
        className="sm:w-4/6 w-full"
      >
        <TabsList className="flex w-full gap-1 overflow-scroll no-scrollbar h-full rounded-none justify-between items-center">
          <TabsTrigger
            value="hair"
            className="hover:underline w-full rounded-none"
            onClick={() =>
              nav("/services/lebanon/lebanonWomen/hair")
            }
          >
            HAIR
          </TabsTrigger>
          <TabsTrigger
            value="princess"
            className="hover:underline w-full rounded-none"
            onClick={() =>
              nav("/services/lebanon/lebanonWomen/bride")
            }
          >
            BRIDE
          </TabsTrigger>
          <TabsTrigger
            value="makeup"
            className="hover:underline w-full rounded-none"
            onClick={() =>
              nav("/services/lebanon/lebanonWomen/makeup")
            }
          >
            MAKEUP
          </TabsTrigger>
          <TabsTrigger
            value="nail"
            className="hover:underline w-full rounded-none"
            onClick={() =>
              nav("/services/lebanon/lebanonWomen/nail")
            }
          >
            NAIL
          </TabsTrigger>
          <TabsTrigger
            value="spa"
            className="hover:underline  w-full rounded-none"
            onClick={() =>
              nav("/services/lebanon/lebanonWomen/spa")
            }
          >
            SPA
          </TabsTrigger>
        </TabsList>
        <TabsContent value="hair" className="w-full">
          <WomenServices
            columns={womenColumns}
            data={amadeusHairWomenServices}
          />
        </TabsContent>
        <TabsContent value="bride">
          {" "}
          <WomenServices
            columns={womenColumns}
            data={amadeusPrincessServices}
          />
        </TabsContent>
        <TabsContent value="makeup">
          <WomenServices
            columns={womenColumns}
            data={amadeusMakeupLab}
          />
        </TabsContent>
        <TabsContent value="nail">
          {" "}
          <WomenServices
            columns={womenColumns}
            data={amadeusNailLab}
          />
        </TabsContent>
        <TabsContent value="spa">
          <WomenServices
            columns={womenColumns}
            data={amadeusSpaWomen}
          />
        </TabsContent>
      </Tabs>
      <div className="flex flex-col gap-5 justify-end right-32 p-5 items-center lg:fixed top-48">
        <video
          width="200px"
          autoPlay
          muted
          playsInline
          loop
          controls={false}
        >
          <source
            src={"/amadeus-videos/amadeus-ad-1.mp4"}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <p className="text-sm text-center">
          match our products with your required service
        </p>
        <Button
          className="rounded-none w-36"
          onClick={() => {
            nav("/shop");
          }}
        >
          SHOP NOW
        </Button>
      </div>
    </div>
  );
}
