import React from "react";
import { amadeusServices } from "./priceList";
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <div className="sm:mt-36 mt-16 p-5">
      <h1 className="sm:text-2xl mb-5 text-xl font-montserrat font-medium">
        AMADEUS SERVICES
      </h1>
      <span>
        How about some "me, myself and eye" from the master
        of the craft:{" "}
        <span className="font-trajanBold">AMADEUS</span>.
      </span>
      <div className="mt-10 flex justify-center sm:justify-start flex-wrap sm:gap-20 gap-10 sm:pl-32 sm:pr-32 p-0">
        {amadeusServices.map((service, index) => (
          <Link
            to={`${service.link}`}
            key={service.id}
            data-aos={"fade-right"}
            className="relative border grow sm:basis-1/4 basis-1/3 "
            data-aos-delay={index + "00"}
          >
            <div className="sm:h-1/4 h-1/3 bottom-0 w-full  absolute bg-gradient-to-t from-[#ffffff] to-[#ffffff00]"></div>
            <img
              src={service.image || "/salon-women.png"}
              alt="amadeus service"
              className="h-full"
            />
            <p className="absolute bottom-2 text-center font-light text-xl w-full  dark:text-neutral-800 hover:underline">
              {service.service}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
