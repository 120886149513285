import {
  LoaderIcon,
  ShoppingCartIcon,
  TrashIcon,
} from "lucide-react";
import React from "react";
import { useCookies } from "react-cookie";
import { useQueryClient } from "react-query";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import { apiCall } from "../../src/lib/api";
import { toast } from "./ui/use-toast";

export default function RemoveFromCart({
  itemId,
  name,
  cart,
}: {
  itemId: string;
  name: string;
  cart?: boolean;
}) {
  const [cookies, removeCookie] = useCookies([
    "cartKey",
    "userToken",
  ]);
  const [loading, setLoading] = React.useState(false);
  const reactQuery = useQueryClient();

  const deleteItem = async () => {
    const key = await cookies.cartKey;
    setLoading(true);
    const res = await fetch(
      !cookies.userToken
        ? `${apiCall}/wp-json/cocart/v2/cart/item/${itemId}?cart_key=${key}`
        : `${apiCall}/wp-json/cocart/v2/cart/item/${itemId}`,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Authorization: !cookies.userToken
            ? ""
            : `Bearer ${cookies.userToken}`,
        },
      }
    );
    if (!res.ok) {
      setLoading(false);
      console.log(`HTTP error: ${res.status}`);
    }
    const response = await res.json();

    response.items && response.items.length < 1
      ? removeCookie("cartKey", { path: "/" })
      : console.log("no cart");
    reactQuery.refetchQueries(["cartItems"]);
    reactQuery.refetchQueries(["wooProductsHome"]);
    reactQuery.refetchQueries(["wooProductsOne"]);
    reactQuery.refetchQueries(["wooProductsTwo"]);
    reactQuery.refetchQueries(["singleProduct"]);
    toast({
      title: `${name} removed from cart`,
      action: <ShoppingCartIcon />,
      className: "fixed top-0 left-0 sm:relative",
    });

    setLoading(false);
    return response;
  };
  return loading ? (
    <LoaderIcon className="animate-spin w-4 h-4" />
  ) : cart ? (
    <CrossCircledIcon
      className="w-5 h-5 text-red-500 hover:fill-red-500 cursor-pointer"
      onClick={() => deleteItem()}
    />
  ) : (
    <TrashIcon
      className="w-4 h-4 text-red-500 hover:fill-red-500 cursor-pointer"
      onClick={() => deleteItem()}
    />
  );
}
