import { Input } from "../../../src/components/ui/input";
import { Button } from "../../../src/components/ui/button";
import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "../../../src/components/ui/card";
import { Label } from "@radix-ui/react-label";
import { format } from "date-fns";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../src/components/ui/radio-group";
import {
  CalendarIcon,
  CreditCard,
  DollarSign,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../src/components/ui/popover";
import { cn } from "../../../src/lib/utils";
import { WooCommerce } from "../../../src/lib/api";
import { Calendar } from "../../../src/components/ui/calendar";
import CartDetails from "../cart/cartDetails";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { OrderData } from "../../../src/lib/interfaces";
import { apiCall } from "../../../src/lib/api";
import BackButton from "../../../src/components/backButton";

export default function Checkout() {
  const [cookies] = useCookies([
    "userToken",
    "cartKey",
    "userId",
  ]);
  const [date, setDate] = React.useState<Date>();
  const [payment, setPayment] = React.useState("cash");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [town, setTown] = React.useState("");
  const [building, setBuilding] = React.useState("");

  const { data: cartItems } = useQuery(
    ["cartItems"],
    async () => {
      const key = await cookies.cartKey;
      const res = await fetch(
        !cookies.userToken
          ? `${apiCall}/wp-json/cocart/v2/cart/?cart_key=${key}`
          : `${apiCall}/wp-json/cocart/v2/cart/`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: !cookies.userToken
              ? ""
              : `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }
      const response = await res.json();
      return response;
    },
    { enabled: !!cookies.cartKey || !!cookies.userToken }
  );
  const { data: billing } = useQuery(
    ["users/billing"],
    async () => {
      const res = await WooCommerce.get(
        `customers/${cookies.userId}`
      )
        .then((response: any) => {
          setFirstName(response.data.billing.first_name);
          setLastName(response.data.billing.last_name);
          setEmail(response.data.billing.email);
          setPhone(response.data.billing.phone);
          setCountry(response.data.billing.country);
          setCity(response.data.billing.city);
          setTown(response.data.billing.state);
          setBuilding(response.data.billing.address_1);
          return response.data.billing;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    },
    { enabled: !!cookies.userToken }
  );
  const lineItems = cartItems?.items?.map((item: any) => {
    return {
      product_id: item.id,
      quantity: item.quantity.value,
    };
  });
  const orderDetails: OrderData = {
    customer_id: cookies.userId || 0,
    payment_method: payment,
    payment_method_title: "",
    set_paid: false,
    billing: {
      first_name: firstName,
      last_name: lastName,
      address_1: building,
      city: city,
      state: town,
      country: country,
      email: email,
      phone: phone,
    },
    shipping: {
      first_name: "",
      last_name: "",
      address_1: "",
      city: "",
      state: "",
      country: "",
    },
    line_items: lineItems,
    shipping_lines: [],
  };

  return (
    <div className="sm:mt-36 p-5 mt-16 mb-16">
      <div className="flex gap-2 items-center mb-5">
        <BackButton />
        <h1 className="font-montserrat font-medium text-2xl">
          Checkout
        </h1>
      </div>

      <div className="flex w-full gap-40 sm:flex-row flex-col font-montserrat">
        <div className="w-full shadow-2xl ">
          <form>
            <Card className="rounded-none">
              <CardHeader className="border-b">
                <h2 className="text-xl font-medium">
                  Billing Information
                </h2>
                <CardDescription>
                  Add your billing information here
                </CardDescription>
              </CardHeader>
              <CardContent className="mt-5">
                <div className="w-full gap-10 flex flex-col">
                  <div className="flex flex-col gap-10">
                    <h3 className="font-montserrat font-medium underline underline-offset-4">
                      User Info
                    </h3>
                    <div className="flex w-full items-center gap-10">
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="firstName"
                          className="text-sm"
                        >
                          <span>First Name</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          name="firstName"
                          id="firstName"
                          required
                          className="outline-none rounded-none"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) =>
                            setFirstName(e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="lastName"
                          className="text-sm"
                        >
                          <span>Last Name</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          required
                          className="outline-none rounded-none"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) =>
                            setLastName(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center gap-10">
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="email"
                          className="text-sm"
                        >
                          <span>Email</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          name="email"
                          id="email"
                          required
                          type="email"
                          className="outline-none rounded-none"
                          placeholder="Email"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="phone"
                          className="text-sm"
                        >
                          <span>Phone Number</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          id="phone"
                          name="phone"
                          required
                          className="outline-none rounded-none"
                          placeholder="Phone Number"
                          value={phone}
                          onChange={(e) =>
                            setPhone(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-10">
                    <h3 className="font-montserrat font-medium underline underline-offset-4">
                      Address Info
                    </h3>
                    <div className="flex w-full items-center gap-4">
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="country"
                          className="text-sm"
                        >
                          <span>Country</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          id="country"
                          name="country"
                          placeholder="Country"
                          value={country}
                          required
                          className="outline-none rounded-none"
                          onChange={(e) =>
                            setCountry(e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="City"
                          className="text-sm"
                        >
                          <span>City</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          name="city"
                          id="city"
                          placeholder="City"
                          value={city}
                          required
                          className="outline-none rounded-none"
                          onChange={(e) =>
                            setCity(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center gap-4">
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="town"
                          className="text-sm"
                        >
                          <span>Town</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          id="town"
                          name="town"
                          placeholder="Town"
                          value={town}
                          required
                          className="outline-none rounded-none"
                          onChange={(e) =>
                            setTown(e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <Label
                          htmlFor="building"
                          className="text-sm"
                        >
                          <span>Building</span>
                          <span className="text-red-500">
                            *
                          </span>
                        </Label>
                        <Input
                          id="building"
                          name="building"
                          placeholder="Building"
                          required
                          className="outline-none rounded-none"
                          value={building}
                          onChange={(e) =>
                            setBuilding(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-10">
                    <h3 className="font-montserrat font-medium underline underline-offset-4">
                      Payment
                    </h3>
                    <div className="flex w-full items-center gap-4">
                      <RadioGroup
                        defaultValue="cash"
                        className="flex jusitfy-between w-full gap-4 sm:flex-row flex-col"
                        value={payment}
                        name="paymentType"
                        onValueChange={(e) => setPayment(e)}
                      >
                        <div className="flex items-center space-x-2 w-full">
                          <RadioGroupItem
                            value={"cash"}
                            id="pp"
                          />
                          <Label
                            htmlFor="cash"
                            className="flex text-sm gap-1 items-center"
                          >
                            <DollarSign className="w-4 h-4" />
                            <span> Cash On Delivery</span>
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2 w-full">
                          <RadioGroupItem
                            value="areeba"
                            id="areeba"
                          />
                          <Label
                            htmlFor="areeba"
                            className="flex gap-1 items-center text-sm"
                          >
                            <CreditCard className="w-5 h-5" />
                            <span>Pay By Card</span>
                          </Label>
                        </div>
                      </RadioGroup>
                    </div>
                    {payment === "areeba" && (
                      <div className="flex w-full flex-col items-center gap-4 mt-5">
                        <div className="flex gap-4 w-full">
                          <div className="flex flex-col gap-1 w-full">
                            <Label
                              htmlFor="holderName"
                              className="text-sm"
                            >
                              Card Holder Name
                            </Label>
                            <Input
                              id="holderName"
                              required
                              className="outline-none rounded-none"
                              placeholder="i.e John Smith"
                            />
                          </div>
                          <div className="flex flex-col gap-1 w-full">
                            <Label
                              htmlFor="cardNumber"
                              className="text-sm"
                            >
                              Card Number
                            </Label>
                            <Input
                              id="cardNumber"
                              required
                              className="outline-none rounded-none"
                              placeholder="1234567890..."
                            />
                          </div>
                        </div>
                        <div className="flex gap-4 w-full">
                          <div className="w-full flex flex-col gap-1">
                            <Label className="text-sm">
                              Expiry Date
                            </Label>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    " justify-start text-left font-normal w-full rounded-none",
                                    !date &&
                                      "text-muted-foreground"
                                  )}
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />
                                  {date ? (
                                    format(date, "PPP")
                                  ) : (
                                    <span>Expiry Date</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0 ">
                                <Calendar
                                  mode="single"
                                  selected={date}
                                  onSelect={setDate}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                          </div>
                          <div className="flex flex-col gap-1 w-full">
                            <Label
                              htmlFor="securityCode"
                              className="text-sm"
                            >
                              Security Code
                            </Label>
                            <Input
                              id="securityCode"
                              required
                              className="outline-none rounded-none"
                              placeholder="123"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </form>
        </div>
        <CartDetails checkout order={orderDetails} />
      </div>
    </div>
  );
}
