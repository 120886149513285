import React, { useState } from "react";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Check, LoaderIcon, X } from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import { apiCall } from "../../../src/lib/api";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import EyePassword from "../../../src/components/eyePassword";

export default function Signup() {
  const { toast } = useToast();
  const [cookies, setCookie] = useCookies([
    "userToken",
    "userRole",
    "userId",
    "userFirstName",
    "userLastName",
  ]);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [pass, setPass] = useState("");
  const [loader, setLoader] = useState(false);
  const nav = useNavigate();

  async function LoginAfterSignUp() {
    toast({
      title: "Sign up successful, Signing In...",
      action: <Check className="fill-green-500" />,
    });
    try {
      const response = await fetch(
        `${apiCall}/wp-json/jwt-auth/v1/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: user,
            password: pass,
          }),
        }
      );
      const res = await response.json();

      if (res?.token) {
        // router.push(`/profile`);
        setCookie("userToken", res.token, {
          path: "/",
          secure: false,
        });

        nav("/profile");
      }
    } catch (err) {
      throw err;
    }
  }
  async function saveUser() {
    if (
      fName.length < 1 ||
      lName.length < 1 ||
      user.length < 1 ||
      pass.length < 1 ||
      email.length < 1
    ) {
      toast({
        title: "Please fill out all fields",
        action: (
          <ExclamationTriangleIcon className="text-orange-500" />
        ),
      });
      return;
    }
    setLoader(true);
    try {
      const response = await fetch(
        `${apiCall}/wp-json/amadeus/v1/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: fName,
            last_name: lName,
            username: user,
            email: email,
            password: pass,
          }),
        }
      );
      const res = await response.json();
      setLoader(false);
      setCookie("userId", res?.data.user_id, {
        path: "/",
        secure: false,
      });
      LoginAfterSignUp();
    } catch (error) {
      toast({
        title: "Error signing up, please try again",
        action: <X className="text-red-500" />,
      });
      console.error("Error signup", error);
    }
  }
  React.useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter"
      ) {
        saveUser();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [user, pass, fName, lName, email]);
  return (
    <div className="w-screen h-dvh flex justify-center items-center flex-col p-10 sm:p-0">
      <form
        className="shadow-2xl sm:w-96 w-full p-10 rounded flex flex-col gap-1 items-center"
        onSubmit={() => {
          saveUser();
        }}
      >
        <img
          src="/logo-aaa.png"
          alt="logo"
          width={50}
          className="dark:invert mb-5"
        />
        <h1 className="font-bold text-center  text-xl mb-5">
          Sign Up
        </h1>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Input
              name="first_name"
              required
              autoComplete="first name"
              type="text"
              placeholder={`First Name`}
              className={`text-neutral-900 focus:outline-none w-full dark:text-neutral-200 rounded-none`}
              value={fName}
              onChange={(e) => {
                setFName(e.target.value);
              }}
            />
            <Input
              name="last_name"
              required
              type="text"
              autoComplete="last name"
              placeholder="Last Name"
              className={`text-neutral-900 focus:outline-none w-full dark:text-neutral-200 rounded-none`}
              value={lName}
              onChange={(e) => {
                setLName(e.target.value);
              }}
            />
          </div>
          <Input
            name="username"
            required
            type="text"
            placeholder="Username"
            autoComplete="username"
            className={`text-neutral-900 focus:outline-none w-full dark:text-neutral-200 rounded-none`}
            value={user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
          />
          <Input
            name="email"
            required
            type="email"
            placeholder="Email"
            autoComplete="email"
            className={`text-neutral-900 focus:outline-none w-full dark:text-neutral-200 rounded-none`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <EyePassword value={pass} setValue={setPass} />
        </div>
        <Button
          className="mt-1 w-full rounded-none"
          type="submit"
        >
          {loader ? (
            <LoaderIcon className="animate-spin" />
          ) : (
            `SIGN UP`
          )}
        </Button>
        <div className=" mt-5 text-sm">
          {`Already have an account? `}
          <Link className="hover:underline" to={"/login"}>
            SIGN IN
          </Link>
        </div>
      </form>
    </div>
  );
}
