import { ArrowLeft } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

export default function BackButton() {
  return (
    <button
      onClick={() => window.history.back()}
      className=" z-50 text-neutral-500  flex gap-2"
    >
      <ArrowLeft className="w-4" />
    </button>
  );
}
