import Subscribe from "../../../src/components/subscribe";
import SocialIcons from "../../../src/components/socialIcons";
import { Link } from "react-router-dom";

export default function Footer() {
  const brandsList = [
    {
      id: 368,
      name: "Kerastase",
    },
    {
      id: 437,
      name: "Schwarzkopf",
    },
  ];
  return (
    <div
      id="footer"
      className="z-30 bg-white dark:bg-neutral-900 pr-10 pl-10 pb-5 flex flex-col justify-between gap-20 border-t pt-12 dark:border-t-neutral-800 font-montserrat"
    >
      <div className="flex flex-col gap-2 w-full items-start">
        <h6 className="sm:text-md text-sm font-light">
          Subscribe to our newsletter!
        </h6>
        <p className="text-[10px] text-neutral-600">
          You may unsubscribe at any time.{" "}
        </p>
        <div>
          <Subscribe />
        </div>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap justify-between  gap-y-20 gap-10 w-full text-neutral-600 dark:text-neutral-100">
        <div className="flex flex-col gap-10 lg:w-1/5 w-1/2">
          <h6 className="font-montserrat font-medium text-md">
            DISCOVER
          </h6>
          <div className="flex flex-col gap-5 text-[12px]">
            <Link
              to="/"
              className="hover:translate-x-2 duration-300"
            >
              SHAMPOOS
            </Link>
            <Link
              to="/shop"
              className="hover:translate-x-2 duration-300"
            >
              CONDITIONERS
            </Link>
            <Link
              to="/shop"
              className="hover:translate-x-2 duration-300"
            >
              STYLING
            </Link>
            <Link
              to="/services"
              className="hover:translate-x-2 duration-300"
            >
              OILS AND SERUMS
            </Link>
            <Link
              to="/about"
              className="hover:translate-x-2 duration-300"
            >
              TREATMENTS
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:w-1/5 w-1/3">
          <h6 className="font-montserrat font-medium text-md">
            BRANDS
          </h6>
          <div className="flex flex-col gap-5 text-[12px]">
            {brandsList?.map((brand) => (
              <Link
                key={brand.id}
                to={`/shop/category/${
                  brand.id
                }/${encodeURIComponent(brand.name)}`}
                className="hover:translate-x-2 duration-300"
              >
                {brand.name.toLocaleUpperCase()}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:w-1/5 w-1/3">
          <h6 className="font-montserrat font-medium text-md">
            MENU
          </h6>
          <div className="flex flex-col gap-5 text-[12px]">
            <Link
              to="/"
              className="hover:translate-x-2 duration-300"
            >
              HOME
            </Link>
            <Link
              to="/shop"
              className="hover:translate-x-2 duration-300"
            >
              SHOP
            </Link>
            <Link
              to="/services"
              className="hover:translate-x-2 duration-300"
            >
              SERVICES
            </Link>
            <Link
              to="/about"
              className="hover:translate-x-2 duration-300"
            >
              ABOUT
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:w-1/5 w-1/3">
          <h6 className="font-montserrat font-medium text-md">
            ACCOUNT
          </h6>
          <div className="flex flex-col gap-5 text-[12px]">
            <Link
              to="/profile"
              className="hover:translate-x-2 duration-300"
            >
              PROFILE
            </Link>
            <Link
              to="/cart"
              className="hover:translate-x-2 duration-300"
            >
              CART
            </Link>
            <Link
              to="/wishlist"
              className="hover:translate-x-2 duration-300"
            >
              WISHLIST
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-center items-center pb-5">
          <img
            src="/logo.png"
            alt="amadeus-logo"
            className="w-28 dark:invert "
          />
        </div>
        <div className=" flex sm:flex-row gap-20 flex-col justify-between items-center w-full sm:h-fit pt-5 border-t">
          <div className="flex-row sm:gap-20 text-[12px] sm:flex hidden w-full">
            <Link
              to={"#"}
              className="hover:translate-x-2 duration-300"
            >
              PRIVACY POLICY
            </Link>
            <Link
              to="#"
              className="hover:translate-x-2 duration-300"
            >
              TERMS & CONDITIONS
            </Link>
            <Link
              to="/contact"
              className="hover:translate-x-2 duration-300"
            >
              CONTACT
            </Link>
          </div>
          <SocialIcons />
        </div>
      </div>
    </div>
  );
}
