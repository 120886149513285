import React from "react";
import { WooCommerce } from "../lib/api";
import { Star } from "lucide-react";
import { useCookies } from "react-cookie";
import { toast } from "./ui/use-toast";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { useQueryClient } from "react-query";
export default function ProductReview({
  rating,
  productId,
}: {
  productId: number;
  rating: number;
}) {
  const [cookies] = useCookies(["userName", "userEmail"]);
  const reactQuery = useQueryClient();
  const [hoverRating, setHoverRating] = React.useState(0); // State for the hovered rating

  async function rateProduct(rate: number) {
    const data = {
      product_id: productId,
      rating: rate,
      reviewer: cookies.userName,
      review: "sss",
      reviewer_email: cookies.userEmail,
    };

    WooCommerce.post("products/reviews", data)
      .then((response: any) => {
        toast({
          title: "Rating Successful",
          description: `You Rated ${response?.data?.rating} Stars`,
          action: (
            <StarFilledIcon
              fill={"gold"}
              className="size-6 text-yellow-400 fill-yellow-400"
            />
          ),
        });
        reactQuery.refetchQueries(["productsRating"]);
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
  }

  return (
    <div className="flex gap-2">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          onClick={() => rateProduct(i + 1)} // Set rating on click
          onMouseEnter={() => setHoverRating(i + 1)} // Update hover rating on mouse enter
          onMouseLeave={() => setHoverRating(0)} // Reset hover rating on mouse leave
          color="gold"
          className={`size-5 hover:fill-yellow-400 cursor-pointer`}
          fill={
            i < rating && i < hoverRating
              ? "gold"
              : "white dark:neutral-900"
          } // Fill stars based on hover or selected rating
        />
      ))}
    </div>
  );
}
